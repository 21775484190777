@font-face {
  font-family: droid-Kufi;
  src: url(./assets/fonts/DroidKufi-Regular.ttf) format("truetype");
}

@font-face {
  font-family: droid-Kufi-bold;
  src: url(./assets/fonts/DroidKufi-Bold.ttf) format("truetype");
}

@font-face {
  font-family: SourceSansPro-Regular;
  src: url(./assets/fonts/SourceSansPro-Regular.otf) format("truetype");
}

@font-face {
  font-family: SourceSansPro-Bold;
  src: url(./assets/fonts/SourceSansPro-Bold.otf) format("truetype");
}

@font-face {
  font-family: SourceSansPro-Light;
  src: url(./assets/fonts/SourceSansPro-Light.otf) format("truetype");
}

@font-face {
  font-family: Rubik-Light;
  src: url(./assets/fonts/Rubik-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: Outfit-Thin;
  src: url(./assets/fonts/Outfit-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family:  Noto-Kufi-Arabic;
  src: url(./assets/fonts/NotoKufiArabic-VariableFont_wght.ttf) format("truetype");
}

body,a,p,div{
  font-family: var(--EnfontFamily)
}
h1, h2, h3, h4 , h5, h6
{
  font-family: var(--EnHeadlinefontFamily)
}
body{
  background: var(--homeBgColor);
  color: var(--textColor);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll !important;
}
@media(max-width: 768px){
  body{
    background-position: center center;
  }

}



body[dir="rtl"] {
  
  div,a,p{
  font-family: var(--ArfontFamily)
  }
  h1, h2, h3, h4 , h5, h6
{
  font-family: var(--ArHeadlinefontFamily)
}
font-family: var(--ArfontFamily)

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  // background: transparent;
  width: 0px;
}
.games::-webkit-scrollbar {
  width: 10px;
  color: blueviolet;
}

.games::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(70, 70, 70, 0.3);
  background: #f1f1f1; 
  border-radius: 10px;
}

.games::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888; 
  -webkit-box-shadow: inset 0 0 6px rgba(145, 27, 27, 0.5);
}
a.reload {
  color: var(--textColor);
  text-decoration: underline;
  padding: 0 3px;
}
.rtl-loader {
  transform: scale(-1, 1);
}
.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  flex-direction: column;
  font-size: 24px;
  a {
    font-size: 18px;
    padding: 25px 0;
  }
}
.link {
  font-size: 18px;
  color: var(--primaryColor);
}
img:not([alt]),
img[alt=""] {
  outline: 5px solid red;
}
.sc-hzDKRC
{
  background-image: url('./assets/images/bg-banners/bg.png') !important;
}




