body[dir="rtl"] {
    .games div a:last-child{
        margin: 20px 5px 5px 20px;
    }

    .gamesName
  {
    direction: ltr!important;

  }
}

.hosUpP {
    
    direction: ltr;
}
.gamesName
  {
    width: 165px!important;

  }

  @media (max-width: 996px) {
    .gamesName
    {
    width: 75px!important;
    }
  }