
.rankingHeader{
    background: var(--leaderboard) center center no-repeat;
    height: 211px ;
    background-size: cover;
}
.rankingHeadercom
{
    height: 30vh!important;

}
.smallPlatecom ,.bigPlatecom{
    background: url("../../assets/images/HomepagecomGamezroid/Rectangle.svg") no-repeat;
    height: 27px;
    width: 82px;
    padding: 3px 0px;
    color: #00559F!important;

}
// .bigPlatecom{
//     background: url("../../assets/images/HomepagecomGamezroid/Rectangle.svg") no-repeat;
//     height: 12px;
//     width:127px;
//     padding: 30px 0px;
//     color: #00559F;

// }
.secondcomFrame, .firstcomFrame{
   // padding-bottom:10px;
}
.firstcomFrame ,.secondcomFrame ,.thirdcomFrame{
    height: 73px;
    width: 62px;
    position: absolute;
    top: 113px;
}
.secondcomFrame{
    background: url("../../assets/images/HomepagecomGamezroid/winner2.png") no-repeat;
}
.firstcomFrame{
    background: url("../../assets/images/HomepagecomGamezroid/winner1.png") no-repeat;
    height:114px;
    width: 72px;
    top: 80px;
}
.thirdcomFrame{
    background: url("../../assets/images/HomepagecomGamezroid/winner3.png") no-repeat;
    //padding-bottom: 11px;
}
body[dir="rtl"] {
    .thumb .xsAvatar {
    height: 36px;
    width: 37px;
    }
}
.name, .points
{
 color: var(--sidebardetailscolor)

}