html[data-theme="asiacell"] {
    --primaryColor:#c30d09;
    --borderActiveTab : #c30d09;
    --activebgTaps : #c30d09;

    --titleColor : #383b40;
    --secondaryBgColor: #12151d;
    --BgNavScroll : #12151d;

    --lightBgColor: #383b40;
    --darkBgColor: #1a1f2a;
    --homeBgColor:#ffffff;
    --appBg:#ffffff;
    --bgHeaderMenucolor:#c30d09;
    --activeColor:black;
    --borderActiveColor: #c30d09;
    --homeTabs: #27313b;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : black;
      --mainTabsColor: #ffffff;
  --displaytabs: flex;
    --sidebardetailscolor: black;
    --LoveColor:#f4323d;
    --gameBgColor : #ffffff;
    --gameBorderradius : 0;
    --gameMargin : 0;
    --colorActive : #fff;
    --profileTextColor : #fff;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;

    --gamename:#12151d;
    --unSubscribeColor: #12151d;

    --borderRadiusSize : 22px;
    --background-size: 'contain';
    --Allcolor: #c30d09;
    --gameDetails : #0f0e0e;
    --gameDetailsType : rgb(120, 120, 120);
    --loginLogoWidth : 350px;
    --backgroundcat :#c30d09;
    --activeBackground: #c30d09;
    --imgBorder : 22%;
    --gameMarginTop : 0;
    --tabMargin :0;
    --gameBorderB :1px solid rgb(66, 69, 74);
    --gamePaddingB : 0;
    --linkGamemargin : 20px 3px 5px 15px;
    --navbarLogoWidth : 120px;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --loginLogo: url(../assets/images/logos/gamezroid/loginLogo.png);
    --navbarLogo: url(../assets/images/logos/asiacell/loginLogo.svg);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --subscribe: url(../assets/images/background/subscribe.jpg);
    --play : url(../assets/images/logos/gamezroid/play.png);
    --homepageBgCompetition : url(../assets/images/bg-banners/home-competition.jpg);
    --leaderboard-icon: url(../assets/images/HomepagecomGamezroid/leader.png);
    --help-icon: url(../assets/images/HomepagecomGamezroid/help.png);
    --competitionLogo: url(../assets/images/HomepagecomGamezroid/logo1.png);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);

  }