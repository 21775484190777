.exculsiveBanner
{
    /*background: url("../../assets/images/background/exclusivebanner.jpg");
    width: 100%;
    height: 500px;*/
    
}
.clusgame>div{
    background-color: var(--primaryColor);
}

body[dir="rtl"]
{
    .exculsiveText
    {
        font-family: SourceSansPro-Regular;
    }

}