// .ncellTos {
//   font-size: 13px;
//   margin-top: 20px;
// }

// .links-Section{
//   position: absolute;
//     top: 30%;
//     border: 1px solid red;
// }

// .gamezoneSignup
// {
//   background: rgb(238 90 17);
// }

.condtion-list
{
    text-align: left;
    line-height: 18px;
    padding: 0 5px;
    color: #dadada;
}

.condtion-list div
{
    padding: 5px 30px 0;
    color: #dadada!important;
  }

.condtion-list div p
{
    // color: #fff!important;
}

.condtion-list li
{

}
.btn-egypt
{
    width: 75%!important;
    background-color: #fff!important;
    color: #6a1d81!important;
    border: 2px solid #6a1d81!important;
    margin-top: 39px!important;
}

body[dir="rtl"] {
    .footerCondtion , .condtion-list {
        text-align: right!important;
    }
}

.mondiacontent
{

  color: #f8e250;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  line-height: 1.2em;
  font-size: 1.4em;
  font-weight: 900;
  padding: .6em 1em;
}

.jawwalgamezoridncellTos p
{    line-height: 20px;
 // background: url("../../assets/images/mondia/freegame.png");
}

.jawwalgamezoridFooter
{
  margin-bottom: 0!important;

}

.mondiaoperatorSignup
{
  background : url("../../assets/images/mondia/subscription-bg.jpg") no-repeat center center;
  background-size: cover;
}




body[dir="rtl"]
{
  .footerCondtion
  {
    text-align: right!important;
  }
}

@media(max-width: 1024px){
  .links-SectionMondia
  {
    width: 100%!important;

  }
}


@media(max-width: 540px){
    .formsLayout .jawwalgamezoridFooter {
       margin-bottom: 10px!important; 
  }
  .jawwalgamezoridncellTos p 
  {
    //margin: 8px;
  }

  .btnmondia
  {
    width: 80%!important;
  }

 
  
  .links-Sectionjawwalgamezorid button{
    margin: 20px auto!important;
  }
    
  
  }

  @media(max-width: 418px){
    //   .formsLayout .jawwalgamezoridFooter {
    //      margin-bottom: 0!important; 
    // }
    // .jawwalgamezoridncellTos p 
    // {
    //   line-height: 16px;
    // }
    
    .links-Sectionjawwalgamezorid button{
      margin: 40px auto!important;
    }
      
    
    }

    @media(max-width: 414px){
      //   .formsLayout .jawwalgamezoridFooter {
      //      margin-bottom: 0!important; 
      // }
      .jawwalgamezoridncellTos p 
      {
        line-height: 16px;
      }
      
      .links-Sectionjawwalgamezorid button{
        margin: 16px auto!important;
      }
        
      
      }

@media(max-width: 411px){
  .formsLayout .jawwalgamezoridFooter {
     margin-bottom: 0!important; 
}
.jawwalgamezoridncellTos p 
{
  //margin: 10px;
}

.links-Sectionjawwalgamezorid button{
  margin: 40px auto!important;
}
  

}





@media(max-width: 375px){
  .formsLayout .jawwalgamezoridFooter {
    // margin-bottom: 70px!important; 
}
.jawwalgamezoridncellTos p 
{
  line-height: 16px;
}

.links-Sectionjawwalgamezorid button{
  margin: 25px auto!important;
}
  

}



@media(max-width: 360px){
  .formsLayout .jawwalgamezoridFooter {
     margin-bottom: 0!important; 
}
.jawwalgamezoridncellTos p 
{
  margin: 8px;
  font-size: 10px!important;
  line-height: 15px;
}

.links-Sectionjawwalgamezorid button{
  margin: 20px auto!important;
}
  

}

@media(max-width: 320px){
  .formsLayout .jawwalgamezoridFooter {
     margin-bottom: 0!important; 
}
.jawwalgamezoridncellTos p 
{
  margin: 0px;
  font-size: 10px!important;
}

.links-Sectionjawwalgamezorid button{
  margin: 10px auto!important;
}

.jawwalgames-info p 
{
  font-size: 12px;
}
  

}