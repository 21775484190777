
.headerValntineLogo
{
   // background: url("../../assets/images/valantine/logo1.png") no-repeat center center;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 150px;
    background-size: contain;
}
.comments
{
    background: url("../../assets/images/valantine/Box.png") no-repeat center center;
    width: 100%;
    height: 260px;
    background-size: contain;
    margin-top: 2vh;
    display: flex;
    align-items: center;

}
.comments form 
{
   // width: 100%;
   margin: 0 auto;
}
.valntinesend
{
    background: url("../../assets/images/valantine/Send3.png") no-repeat center center;
    width: 130px;
    height: 45px;
    border: none;
    border-radius: 25px;
    color: white;
    text-transform: uppercase;
    outline: none;
}

.valntinemodal
{
    background: url("../../assets/images/valantine/Mask\ Group\ 1.png") no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;


}
.valntinemodal p
{
    color: black;
}

.ncellTos-valntine
{
    margin-top: 30px!important;

}