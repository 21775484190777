html[data-theme="sabafon"] {
    --primaryColor:#155e93;
    --borderActiveTab : #155e93;
    --activebgTaps : #155e93;

    --titleColor :#ffffff;
    --secondaryBgColor: #12151d;
    --lightBgColor: #383b40;
    --darkBgColor: #1a1f2a;
    --homeBgColor:#0F2135;
    --appBg:#0F2135;
    --bgHeaderMenucolor:#155e93;
    --activeColor:#ffffff;
    --activeBackground: #019BFF;
    --borderActiveColor: #ffffff;
    --homeTabs: #27313b;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : #ffffff;
      --mainTabsColor: #ffffff;
  --displaytabs: none;
    --sidebardetailscolor: #ffffff;
    --LoveColor:#f4323d;
    --colorActive : #fff;
    --profileTextColor : #fff;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;

    --gameBgColor : #0F2135;
    --gameBorderradius : 0;
    --gameMargin : 0;
    --gamename:#ffffff;
    --unSubscribeColor: #fff;

    --borderRadiusSize : 22px;
    --background-size: 'contain';
    --Allcolor: #A43969;
    --gameDetailsType : rgb(120, 120, 120);
    --loginLogoWidth : 350px;
    --backgroundcat :#155e93;
    --imgBorder : 22%;
    --gameMarginTop : 0;
    --tabMargin :0;
    --gameBorderB :1px solid #019BFF;
    --gamePaddingB : 0;
    --linkGamemargin : 20px 3px 5px 15px;
    --navbarLogoWidth : 240px;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --loginLogo: url(../assets/images/logos/sabafon/loginLogo.png);
    --navbarLogo: url(../assets/images/logos/sabafon/navLogo.png);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);

  }