
body[dir="rtl"] {
	.wrapper span.lang{
		left:unset;
		right:35px;
    }
    .title{
        text-align: right;
        
    }
}