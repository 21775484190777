/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  margin-top: 5px;
}
.accordion__item {
  padding: 15px 8px;
  :focus {
    outline: none;
  }
}
.accordion__item + .accordion__item {
  border-top: 1px solid rgba(128,128,128,0.1);
}
.accordion__panel p {
  font-size: 17px;
  text-align: left;
  padding: 0 10px 0 40px;
}
.accordion__button {
  :focus {
    border: none;
    outline: none;
  }
  background-color: transparent;
  color: var(--primaryColor);
  cursor: pointer;
  .txt {
    font-size: 18px;
    font-family: SourceSansPro-Bold;
    text-align: left;
    :focus {
      border: none;
      outline: none;
    }
  }
  display: flex;
  .icon {
    height: 21px;
    display: flex;
    align-items: center;
    margin: 0 5px;
    span {
      height: 20px;
      width: 20px;
      background: url(../../assets/icons/plus.svg) center center no-repeat;
      display: block;
      padding: 0 6px;
    }
  }
}
.accordion__button[aria-expanded="true"] {
  .icon span {
    background-image: url(../../assets/icons/minus.svg);
  }
}
.accordion__button:hover {
  background-color: transparent;
  outline: none;
}

.accordion__panel {
  text-align: auto;
  animation: fadein 0.35s ease-in;
  color: var(--sidebardetailscolor);
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

body[dir="rtl"] {
  .txt{
    font-family: droid-Kufi;
    text-align: right;
  }
  .accordion__panel p{
    padding: 0 40px 0 10px;
    text-align: right;
    line-height: 1.44;
  }
}