html[data-theme="ais"] {
    --primaryColor:#9bbe27;
    --borderActiveTab : #9bbe27;
    --activebgTaps : #9bbe27;

    --titleColor : #93d501;
    --secondaryBgColor: #222323;
    --BgNavScroll : #222323;

    --lightBgColor: #383b40;
    --darkBgColor: #1b1f2a;
    --homeBgColor:#356649;
    --appBg:#356649;
    --bgHeaderMenucolor:#9bbe27;
    --activeColor:#12151d;
    --borderActiveColor: #00bcd4;
    --homeTabs: #ffffff;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : #ffffff;
    --mainTabsColor: #3f484e;
    --sidebardetailscolor: #ffffff;
    --LoveColor:#f4323d;
    --gamename:#1b1f2a;
    --unSubscribeColor: #fff;

    --borderRadiusSize : 5px;
    --background-size: contain;
    --Allcolor: #009edd;
    --profileTextColor : #fff;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;

    --backgroundcat :#93d501;
    --activeBackground: #00bcd4;
    --gameDetailsType : rgb(120, 120, 120);
    --loginLogoWidth : 180px;
    --navbarLogoWidth : 240px;
    --gameBgColor : #ffffff;
    --gameBorderradius : 6px;
    --gameMargin : 10px;
    --imgBorder : 50%;
    --tabMargin :25px;
    --linkGamemargin : 20px 15px 15px 20px;
    //--linkGamemargin : 20px 0px 20px 20px;
    --gameBorderB :1px solid #ffffff;
    --games_icon : url(../assets/images/logos/gamezroid/games.png);

    --gameMarginTop : 25px;
    --colorActive : #fff;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --loginLogo: url(../assets/images/logos/default/playit-with-text.svg);
    --navbarLogo: url(../assets/images/logos/default/playit.svg);
      --paymentLogo : url(../assets/images/logos/default/fawry_logo.svg);
    --play : url(../assets/images/logos/gamezroid/play.png);
    --exclusiveGame : url(../assets/images/background/EX.png);
    --exclusiveGamePart2 : url(../assets/images/background/EX.png);
    --competitionBg : url(../assets/images/competetion-new.jpg);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);
     }