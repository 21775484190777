.ncellTos {
  font-size: 13px;
  margin-top: 20px;
}
.resend
{
    margin-top: 25px;
}

.verifymodal,.subscribemodal
{
  //border: 1px solid red;
  height: 100%;
  color: black;
}


.congrut{
  background: url('../../../assets/images/background/subscribe.jpg') center center no-repeat;
  height: 100%;
  background-size: cover;


}

.row
{
  display: flex;
  flex-wrap: wrap;
  text-align: center!important;
  width: 100%;
}

.row .px-5
{
    flex: 0 0 33%;
    
    max-width: 33%;
    
}

.gamezoridcontent
{
  text-align: center;
}
.games-info
{
  max-height: 100%;

}
.games-info span,p
{
  font-weight: bolder;
    font-size: 16px;
}
.games-info p
{
  margin-top: 0.7em;
  font-size: 16px;

}

.games-info img
{
   vertical-align: middle;
    border-style: none;
    margin-left: 5px;


}
.timer p{
  line-height: 5.8px;
  color: #f2d12c;
}
.gamezroidverify
{
  background: var(--gamezroidcolor)!important;
    margin: 20px auto!important;
    color: black!important;
    border: 1px solid #bb2f0c!important;
    border-radius: 32px!important;
    
}


body[dir="rtl"] {
  .games-info img
{
    margin-right: 5px;
    margin-left: 0;
}
.steps ul:nth-child(2)
{
  text-align: right;
}
.Countdown
{
  display: inline-block;
  margin: 0;
  direction: ltr;

}

.stepLists ul{
  padding-right: 10px;
}


}

.freesubscribe
{
    font-weight: 300!important;
    font-size: 12px!important;
    span {
      font-weight: bold!important;
      font-size: 13px!important;
    }
}

.fixedprice
{
  font-weight: 600;
  font-size: 14px;
}
.footer .freeenriched
{
  font-size: 15px!important;

}

.steps
{
  //text-align: center;
  //display: flex;
}
.steps h4
{
  //font: normal normal normal 20px/27px American Typewriter;
}
.steps ul{
  list-style-type: none;
}
.steps ul:nth-child(1)
{
  line-height: 47px;
  padding-top: 18px;
}

.steps ul:nth-child(2)
{
  text-align: left;
}

.steps img{
  padding-right: 12px;
}
.steps img:nth-child(2){
  padding-right: 24px;
}

.stepLists
{
   display: inline-flex;
   line-height: 25px;

}
.stepLists ul{
  padding-left: 0;
}
.decilimer
{
  font-weight: 100!important;
  font-size: 10px!important;
}

.operatorSignup
{
  min-height : 100vh !important;
}

.btntest
{
  color: 'white'!important;
  background-color: '#BB2F14'!important;
  border: '1px solid red'!important;
}
.phonenumber 
{
  color: black!important;
}




.container-loadaing{
  width: 100%;
min-height: 100vh; 
margin: 0;
padding: 0;   //text-align: center !important;
}
.loadingLayout
{
display: flex;
flex-direction: column;
align-items: center;
min-height: 100vh;
margin: 0 20px;
}



.sk-chase {
  width: 80px;
  height: 100px;
  // position: fixed;
  // top: 30% !important;
  // left: 40%;
  // z-index: 1000;
  margin: 0 auto;
  animation: sk-chase 2.5s infinite linear both;
 
}

.test{
 

  width: 40px;
  height: 40px;
  position: fixed;
  top: 35% !important;
  left: 48%;
  z-index: 1000;
  
  
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}


.keep-waiting{

  position: absolute;
  top: 30%;
  width: 75%;
}


.waiting
{
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  background: black;
  opacity: 0.7;
}
.waiting-loading
{
  position: absolute;
  top: 0;
  background: black;
  opacity: 0.7;
  z-index: 12;

}


.ramdankgames-info
{
  background: url('../../../assets/images/HomepagecomGamezroid/numbers.png') center center no-repeat;
  background-size: contain;
  min-height: 95px;
  padding-top: 12px;
}

.textLogin
{
  color: #ffd000;
  text-decoration: underline;
  margin-top: -5px;
}

.ramdankgames-info img{
  margin-top: 14px;
}

.ramdankgames-info p{
  margin-top: 0!important;
  margin :4px!important;
}

.ramdankgame_text{
  margin :0;
  margin-top: -16px;
  font-weight: 500;

}

.ramdankbtn
{
  background: white!important;
  border: none!important;
  color: #0098E4!important;
  width: 60%!important;
  min-width: 200px!important;
  margin-bottom: 25px!important;
}

.ramdanverify::placeholder
{
  color: white;
}

.arrow
{
  height: 100%;
  align-items: center;
  display: flex;
}

.row .px-5 {
  flex: 0 0 31%!important;
}

.ramdankplay
    {

    // background: var(--play) center center no-repeat;
    // height: 190px;
     width: 100%;
   // margin-top: 40px;
    // background-size: var(--background-size)!important;
    }

    .ramdankplay img
    {
      width: 50%;
      height: 170px;
  
    }

    @media(max-width: 768px){
      .ramdankplay img
      {
        width: 100%;
      }
    
    
    }


@media(max-width: 360px){
  .ramdankgames-info p
  {
    font-size: 12px!important;
  }

  .ramdankgames-info img {
    margin-top: 19px;
}
}
@media(max-width: 320px){
  .ramdankgames-info p
  {
    font-size: 12px!important;
  }

  .ramdankgames-info img {
    margin-top: 19px;
}
}

@media(max-width: 280px){
  .ramdankgames-info p
  {
    font-size: 10px!important;
  }

  .ramdankgames-info img {
    margin-top: 21px;
}
}

// {
//   font-weight: bolder;
//     font-size: 16px;
// }





