.roll
{
   // background: url("../../assets/images/HomepagecomGamezroid/roll.png") no-repeat center center;
     width: 100%;
    // height: 90px;
    // outline: none;

}
.walk
{
    
  // background: url("../../assets/images/HomepagecomGamezroid/walkf.png") no-repeat center center;
    width: 100%;
   // height: 100px;
    outline: none;

   // background-size: cover;

}
.block
{
  display: flex;
  position: absolute;
  top: 50%;
  /* background-color: black; */
  /* opacity: 0.8; */
  width: 90%;
  z-index: 2;
  align-items: center;
  justify-content: center;
}
.block img
{
  width: 100%;
  height: 100%;
}
#canvas
{
  width: 100%;
  position: relative;
  top: -10vh;
  //margin-top: -8%;
    // position: absolute;
   
    // top: 47vh;
    // margin: auto;
    // width: 57vw;
    // outline: none;

}
.wheel canvas {
  outline: none;
}
@media(max-width: 1025px){
  #canvas {
      //top: 7vh;
      width: 55%;
    top: -14vh;
  } 
  
  .block
  {
    top: 34%;
  }

}

.popuptextprize
{
  background: url("../../assets/images/HomepagecomGamezroid/popprize.png") no-repeat center center;
  height: 300px;
  width: 340px;
  margin-top: -15%;

}

.chargemsg
{
  height: 135px;
  padding-top: 9px;
}
.popuptextprize p{
  margin-top: 145px;
}
@media(max-width: 768px){
  #canvas {
    top: -24vh;
    width: 100%;
   
  }  
  .block
  {
    top: 38%;
  }
  .popuptextprize
  {
    margin-top: -55%;
  }

}

@media(max-width: 540px){
  
  .block
  {
    top: 41%;
  }
}

@media(max-width: 415px){
  #canvas {
    top: -29vh;
   
  }  
  .block
{
  top: 37%;
}
}

@media(max-width: 376px) and (max-width: 670px)
{
  #canvas {
    top: -35vh;
   
  }  
  .block {
    top: 37%;
}
}

@media(max-width: 376px)
{
  #canvas {
    top: -30vh;
   
  }  
  .block {
    top: 37%;
}
}



@media(max-width: 360px){
  #canvas {
    top: -30vh;
   
  } 
  .block {
    top: 37%;
}
 
}

@media(max-width: 320px){
 
  .block
  {
    top: 42%;
  } 
}


// @media(max-width: 768px){
//     #canvas {
//         top: 18vh;
//     width: 58vw;
//     }    

//   }

//   @media(max-width: 550px){
//     #canvas {
//         top: 38vh;
//     }    

//   }
//   @media(max-width: 415px){
//     #canvas {
//       top: 20vh;
//       width: 91vw;
//     }    

//   }
  

  

//   }
//   @media(max-width: 412px){
//     #canvas {
//       top: 16vh;
//     }    

//   }

//   @media(max-width: 412px) and (max-height: 740px)
//   {

//     #canvas {
//       top: 24vh;
//     }  
//   }

//   @media(max-width: 378px){

//     #canvas {
//       top: 17vh;
//     }  
//   }

//   @media(max-width: 378px) and (max-height: 670px)
//   {

//     #canvas {
//       top: 30vh;
//     }  
//   }

//   @media(max-width: 360px){
//     #canvas {
      
//             top: 26vh;
//             width: 100vw;
//     }    

//   }

//   @media(max-width: 320px){

//     .roll
//     {
//         width: 74%;

//     }
//     #canvas {
//       top: 35vh;
//       width: 100vw;
//     }  
    
//     .walkimg
//     {
//       width: 80%;
//     }
//     .block
//     {
//       top: 339px;
//     }

//   }