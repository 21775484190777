

.container-loadaing{
    width: 100%;
min-height: 100vh; 
margin: 0;
padding: 0;   //text-align: center !important;
}
.loadingLayout
{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 0 20px;
}



.sk-chase {
    width: 80px;
    height: 100px;
    // position: fixed;
    // top: 30% !important;
    // left: 40%;
    // z-index: 1000;
    margin: 0 auto;
    animation: sk-chase 2.5s infinite linear both;
   
  }

  .test{
    // width: 100px;
    // height: 100px;
    // position: fixed;
    // // top: 35% !important;
    // // left: 40%;
    // top: 45% !important;
    // left: 43%;
    // z-index: 1000;

    width: 40px;
    height: 40px;
    position: fixed;
    top: 35% !important;
    left: 48%;
    z-index: 1000;
    
    
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }


  .keep-waiting{
    // width: 200px;
    // height: 200px;
    // position: fixed;
    // top: 55% !important;
    // left: 25%;
    // z-index: 1000;
    //   margin-right: 15px;
    position: absolute;
    top: 30%;
    width: 75%;
  }

