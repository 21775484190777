.paymentImg{
    height: 74px;
    width: 105px;
}
.fawry{
    background: url("../../assets/icons/payment/fawry.svg") center center no-repeat;
}
.sms{
    background: url("../../assets/icons/payment/sms.svg") center center no-repeat;
}
.credit{
    background: url("../../assets/icons/payment/credit.svg") center center no-repeat;
}
.voucher{
    background: url("../../assets/icons/payment/voucher.svg") center center no-repeat;
}

.formPage {
    width: 100%;
    min-height: 100vh;
    //background:var(--homeBgColor);
    margin: 0;
    padding: 0;
  }
  .formsLayout_payment {
    width: 95%;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 2px solid white;
    border-radius: 12px;
    margin: 10px auto!important;

    .header {
      margin-top: 60px;
      .paymentlogo {
        background: var(--paymentLogo) center center no-repeat;
        height: var(--loginLogoHeight);
        width: var(--loginLogoWidth);
        max-width: 350px;
        background-size: var(--background-size);
        //background-size: 160px;
        margin: auto;
      }
    }
    .center {
        width: 100%;
        h2{
          font-size: 20px;

        }
      .form {
        margin: 0 12px;
        overflow: hidden;
        font-size: 14px;
        .input
        {
          background-color: var(--homeBgColor);
          border: none;
          width: calc(100% - 35px);
          border-radius: 5px;
          height: 48px;
          border-bottom: 1px solid #ffffff;
        }
        .input::placeholder
        {
            color: #fff;
           // padding-left: 12px;

        }
      }
    }
    .paymentfooter {
      margin-bottom: 45px;;
      p {
        color: var(--textColor);
        font-size: 17px;
      }
    }
  }
  .normBtn
  {
      margin: 26px!important;
  }

  .selectpayment
  {
    background-color: var(--homeBgColor) !important;
    width: 96%!important;
    height: 48px!important;
    width: calc(100% - 15px)!important;
    border-radius: 5px!important;
    margin-bottom: 10px!important;
    border-bottom: 1px solid #ffffff!important;

  }
  .selectpayment option {
    color: #fff !important;
}
  .paymentModal
  {
    color: black;
  }

  .success
  {
       background: url('../../assets/images/logos/gamezroid/submit_logo.svg') center center no-repeat;
        height: 175px;
        width: var(--loginLogoWidth);
        max-width: 350px;
        background-size: var(--background-size);
        margin: auto;
        margin-bottom: 20px;


  }
  .fawrydata p{
    color: black!important;
    margin: 0 0 -8px 0!important;
    font-size: 16px!important;
  }

  .closemodalfawry{
    position: relative;
    top: 3px;
    right: 45%;
    float: right;
    border: none;
    font-size: 20px;
    font-weight: 900;
    background-color: black;
    border-radius: 15px;
    color: white;
    border: 2px solid #fff;
  }
  .closemodal
  {
    position: relative;
    top: 12px;
    right: 12px;
    float: right;
    border: none;
    font-size: 20px;
    font-weight: 900;
    background-color: white;
  }

  .iconInputpayment .icon{
    top: 17px!important;
    left: 9px!important;
  }

  
 /* select
  {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  select.selectGame
  {
    background-image:
    linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%),
    linear-gradient(to right, skyblue, skyblue);
   background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
   background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;

  }*/