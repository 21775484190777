.save-icon {
  background: url("../../assets/icons/profile/save-inactive.svg") no-repeat;
  height: 23px;
  width: 20px;
}
.edit-icon {
  background: url("../../assets/icons/profile/edit-active.svg") no-repeat;
  height: 23px;
  width: 20px;
}
.calanader {
  background: url("../../assets/icons/profile/calender.svg") no-repeat;
  height: 32px;
  width: 32px;
  padding-bottom: 10px;
}
.camera{
  background: url("../../assets/icons/profile/upload.svg") no-repeat;
  height:28px;
  width:28px;
  position: absolute;
  z-index: 4;
  top: 115px;
}
.egXAgR .profileTop input::placeholder {
  color: rgba(255, 255, 255, 0.733);
 
}
.cancel-btn ,.save-btn{
  background-color: #e40000 !important;
  min-height: 27px !important;
  min-width: 130px!important;
}

.save-btn
{
  
  background-color: green !important;
}

.linkUnsubscribe
{
    color: #009edd!important;
    font-size: 25px!important;
    padding: 15px 0!important;
    border: 1px solid!important;
    width: 60%!important;
    margin: 1px auto!important;
    border-radius: 15px!important;
}

.ramdanbutton
{
  //background: #fff!important;
  background: url('../../assets/images/HomepagecomGamezroid/point.png') no-repeat center center!important;
 // color: #00559F!important;
    font-weight: bold;
    font-family: 'SourceSansPro-Regular'!important;
    height: 65px;
}



