
.video-block{
    margin: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--primaryColor);
    padding-bottom: 15px;
}
.video-block .title{
    font-size: 16px;
    color: var(--sidebardetailscolor)    
}