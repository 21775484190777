.ncellTos {
  font-size: 13px;
  margin-top: 20px;
}

/*.links-Section{
  position: absolute;
    //top: 9%!important;
    top: 30%!important;

}*/

.links-Sectionksa {
  top: 10% !important;
}

.play {
  //background: var(--play) center center no-repeat;
  background: url(../../../assets/images/logos/gamezroid/play.png) center center
    no-repeat;
  height: 175px;
  background-size: var(--background-size) !important;
}

.singupLogo {
  margin: auto !important;
}

.games_img {
  background: url(../../../assets/images/logos/gamezroid/game-bg.png) center
    center no-repeat;
  height: 300px;
  background-size: 300px;
}

.mob-Ksa img[alt="arrow"],
.stc-Ksa img[alt="arrow"],
.zain-Ksa img[alt="arrow"],
.virgin-ksa img[alt="arrow"] {
  margin-top: 23px !important;
}

.virgin-ksa img[alt="virgin"] {
  width: 50px !important;
}

body[dir="rtl"] .btn {
  flex-direction: row-reverse;
}

.resend {
  margin-top: 25px;
}

.verifymodal,
.subscribemodal {
  //border: 1px solid red;
  height: 100%;
  color: black;
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  width: 30%;
  margin: auto;
}
.ReactModal__Content {
  background-color: #f5f6f9 !important;
  border-radius: 28px !important;
  width: 75%;
  margin: auto;
  padding: 15px !important;
}

.header-model {
  display: flex;
  padding: 0 7px;
  justify-content: end;
}
.header-model button {
  margin: 3px !important;
  background-color: transparent;
  border: none;
  font-size: 23px;
  font-family: sans-serif;
  font-weight: 600;
  color: #000000;
  font-family: sans-serif;
}
.btnsModal {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.btn-next {
  display: flex;
  justify-content: center;
  color: #000 !important;
  align-items: center;
  background-color: #f9dd55 !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  margin-top: 10px;
}
.btn-next img[alt="arrow"] {
  margin: 0 15px;
}

.custom-checkbox {
  color: #fff;
  padding: 22px 20px;
  background-color: #fff;
  /* margin: 15px 0; */
  margin-bottom: 24px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  box-shadow: 4px 4px #161d6f1a;
  cursor: pointer !important;
}

// .custom-checkbox
// {
//   color: #fff!important;
//   background-color: #F9DD55!important;

// }
.btnsMobModal .custom-checkbox {
  background: var(--mobColor) !important;
}

.btnsStcModal .custom-checkbox {
  background: #fff !important;
  color: #000 !important;
}

.btnsZainModal .custom-checkbox {
  background: var(--zainColor) !important;
}
.custom-control-label {
  cursor: pointer !important;
}

.checkbox {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: none;
}

.checkbox__input {
  position: absolute;
  top: -100px;
  left: -100px;
}

.checkbox__inner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid #626262;
  background: #f5f6f9 no-repeat center;
  cursor: pointer;
}

.checkbox__input:checked + .checkbox__inner {
  // border-color: #626262;
  background-color: #ff6868;
  background-size: 14px 10px;
}

.congrut {
  background: url("../../../assets/images/background/subscribe.jpg") center
    center no-repeat;
  height: 100%;
  background-size: cover;
}

.row {
  display: flex;
  flex-wrap: wrap;
  text-align: center !important;
  width: 100%;
}

.row .px-5 {
  flex: 0 0 33%;

  max-width: 33%;
}

.gamezoridcontent {
  text-align: center;
}
.games-info {
  max-height: 100%;
}
.games-info span,
p {
  font-weight: bolder;
  font-size: 16px;
}
.games-info p {
  margin-top: 0.7em;
  font-size: 16px;
}

.games-info img {
  vertical-align: middle;
  border-style: none;
  margin-left: 5px;
}
.timer p {
  line-height: 5.8px;
  color: #f2d12c;
}
.gamezroidverify {
  background: var(--gamezroidcolor) !important;
  margin: 20px auto !important;
  color: black !important;
  border: 1px solid #bb2f0c !important;
  border-radius: 32px !important;
}

body[dir="rtl"] {
  .games-info img {
    margin-right: 5px;
    margin-left: 0;
  }
  .steps ul:nth-child(2) {
    text-align: right;
  }

  .stepLists ul {
    padding-right: 10px;
  }

  .header-model {
    justify-content: start;
  }
}

.freesubscribe {
  font-weight: 300 !important;
  font-size: 11px !important;
  span {
    font-weight: bold !important;
    font-size: 11px !important;
  }
}

.fixedprice {
  font-weight: 600;
  font-size: 14px;
}
.footer .freeenriched {
  font-size: 15px !important;
}

.steps {
  //text-align: center;
  //display: flex;
}
.steps h4 {
  //font: normal normal normal 20px/27px American Typewriter;
}
.steps ul {
  list-style-type: none;
}
.steps ul:nth-child(1) {
  line-height: 47px;
  padding-top: 18px;
}

.steps ul:nth-child(2) {
  text-align: left;
}

.steps img {
  padding-right: 12px;
}
.steps img:nth-child(2) {
  padding-right: 24px;
}

.stepLists {
  display: inline-flex;
  line-height: 25px;
}
.stepLists ul {
  padding-left: 0;
}
.decilimer {
  font-weight: 100 !important;
  font-size: 10px !important;
}

.operatorSignup {
  min-height: 100vh !important;
}

.btntest {
  color: "white" !important;
  background-color: "#BB2F14" !important;
  border: "1px solid red" !important;
}
.phonenumber {
  color: black !important;
}

@media (max-width: 950px) {
  .ReactModal__Overlay {
    width: 100%;
  }
}
