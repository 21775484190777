html[data-theme="yemen_mobile"] {
    --primaryColor: #bc0045;
    --borderActiveTab: #bc0045;
    --activebgTaps: #bc0045;
    --titleColor: #BC0042;
    --activeColor: #fff;
    --borderActiveColor: #bc0045;
    --secondaryBgColor: #12151d;
    --lightBgColor: #383b40;
    --darkBgColor: #1a1f2a;
    --homeBgColor: #fff;
    --appBg: #1b1f2a;
    --bgHeaderMenucolor: #bc0045;
    --homeTabs: #27313b;
    --textColor: #ffffff;
    --subBorderColor: #fff;
    --paymentTextcolor: #ffffff;
      --mainTabsColor: #ffffff;
  --displaytabs: flex;
    --sidebardetailscolor: #000;
    --gamename: #000;
    --unSubscribeColor: #fff;
    --LoveColor: #f4323d;
    --gameBgColor: #fff;
    --gameMargin: 0;
    --colorActive: #fff;
    --profileTextColor: #fff;
    --navSearchIconheight: 17px;
    --navSearchIconwidth: 17px;
    --navMenuIconheight: 20px;
    --navMenuIconwidth: 25px;
    --gameMarginTop: 0;
    --tabMargin: 0;
    --gameBorderB: 'none';
    --gamePaddingB: 0;
    --gameBorderradius: 0;
    --borderRadiusSize: 22px;
    --background-size: 75%;
    --Allcolor: #bc0045;
    --linkGamemargin: 20px 3px 5px 15px;
    --loginLogoWidth: 380px;
    --gameDetailsType: rgb(120, 120, 120);
    --navbarLogoWidth: 380px;
    --navHeight: 102px;
    --logoHeight: 90px;
    --loginLogoHeight :90px;
    --backgroundcat: #bc0045;
    --activeBackground: #1a1f2a;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --gameDetails : #000;
    --navHight: 100px;
    --loginLogo: url(../assets/images/logos/yMobile/navLogo.png);
  --navbarLogo: url(../assets/images/logos/yMobile/navLogo.png);
  --leaderboard : url(../assets/images/leaderboard/bg.svg);
  --paymentLogo : url(../assets/images/logos/default/fawry_logo.svg);
  --play : url(../assets/images/logos/gamezroid/play.png);
  --exclusiveGame : url(../assets/images/background/EX.png);
  --exclusiveGamePart2 : url(../assets/images/background/EX.png);
  --competitionBg : url(../assets/images/competetion-new.jpg);
  --menuBg:url(../assets/icons/burger-menu.svg);
  --searchBg:url(../assets/icons/search.svg);
  --EgyBanner: url(../assets/images/Egypt/banner.svg);
  }