// .ncellTos {
//   font-size: 13px;
//   margin-top: 20px;
// }
// .links-Section{
//   position: absolute;
//     top: 30%;
//     border: 1px solid red;
// }
// .gamezoneSignup
// {
//   background: rgb(238 90 17);
// }
.btn_du {
    background: #fff!important;
    // background: linear-gradient(135deg, rgba(171,46,181,1) 14%, rgba(1,170,206,1) 100%)!important;
    border: none!important;
    width: 100%!important;
    color: #0692de!important;
    height: 3.2em!important;
    border: 0!important;
    border-radius: 5em!important;
    font-size: 1.5em!important;
    font-weight: 700!important;
    display: block!important;
    text-transform: capitalize!important;
}

.du-cover {
    // background: url("../../assets/images/mondia/");
}

.mondiacontent {
    color: #f8e250;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    line-height: 1.2em;
    font-size: 1.4em;
    font-weight: 900;
    padding: .6em 1em;
}

.ducontent {
    text-transform: uppercase;
    line-height: 1.2em;
    color: #fff;
    font-size: 1.4em;
    font-weight: 600;
}

.jawwalgamezoridncellTos p {
    line-height: 20px;
    // background: url("../../assets/images/mondia/freegame.png");
}

.jawwalgamezoridFooter {
    margin-bottom: 0!important;
}

.mondiaoperatorSignup {
    background: url("../../assets/images/mondia/subscription-bg.jpg") no-repeat center center;
    background-size: cover;
}

.duoperatorSignup {
    background-color: #ab2eb5;
}

.duFooter h4 {
    color: #f8f2f2!important;
    font-size: 1.5em!important;
    font-weight: inherit!important;
    margin-top: 0.5em!important;
}

.du-info {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.du-info p {
    color: #fff!important;
    font-size: 0.85rem!important;
    width: 33%!important;
    text-align: left!important;
}

.du-info3 p {
    width: 100%!important;
}

.du-info img {
    width: 1.8em;
    margin-right: 0.4em;
    margin-left: 0!important;
}

body[dir="rtl"] {
    .footerCondtion {
        text-align: right!important;
    }
}

@media(max-width: 1024px) {
    .links-SectionMondia {
        width: 100%!important;
    }
}

@media(max-width: 540px) {
    .formsLayout .jawwalgamezoridFooter {
        margin-bottom: 10px!important;
    }
    .jawwalgamezoridncellTos p {
        //margin: 8px;
    }
    .btnmondia {
        width: 80%!important;
    }
    .links-Sectionjawwalgamezorid button {
        margin: 20px auto!important;
    }
}

@media(max-width: 418px) {
    //   .formsLayout .jawwalgamezoridFooter {
    //      margin-bottom: 0!important; 
    // }
    // .jawwalgamezoridncellTos p 
    // {
    //   line-height: 16px;
    // }
    .links-Sectionjawwalgamezorid button {
        margin: 40px auto!important;
    }
}

@media(max-width: 414px) {
    //   .formsLayout .jawwalgamezoridFooter {
    //      margin-bottom: 0!important; 
    // }
    .jawwalgamezoridncellTos p {
        line-height: 16px;
    }
    .links-Sectionjawwalgamezorid button {
        margin: 16px auto!important;
    }
}

@media(max-width: 411px) {
    .formsLayout .jawwalgamezoridFooter {
        margin-bottom: 0!important;
    }
    .jawwalgamezoridncellTos p {
        //margin: 10px;
    }
    .links-Sectionjawwalgamezorid button {
        margin: 40px auto!important;
    }
}

@media(max-width: 375px) {
    .formsLayout .jawwalgamezoridFooter {
        // margin-bottom: 70px!important; 
    }
    .jawwalgamezoridncellTos p {
        line-height: 16px;
    }
    .links-Sectionjawwalgamezorid button {
        margin: 25px auto!important;
    }
}

@media(max-width: 360px) {
    .formsLayout .jawwalgamezoridFooter {
        margin-bottom: 0!important;
    }
    .jawwalgamezoridncellTos p {
        margin: 8px;
        font-size: 10px!important;
        line-height: 15px;
    }
    .links-Sectionjawwalgamezorid button {
        margin: 20px auto!important;
    }
}

@media(max-width: 320px) {
    .formsLayout .jawwalgamezoridFooter {
        margin-bottom: 0!important;
    }
    .jawwalgamezoridncellTos p {
        margin: 0px;
        font-size: 10px!important;
    }
    .links-Sectionjawwalgamezorid button {
        margin: 10px auto!important;
    }
    .jawwalgames-info p {
        font-size: 12px;
    }
}