html[data-theme="mauritania"] {
    --primaryColor:#257CBC;
    --borderActiveTab : #257CBC;
    --activebgTaps : #257CBC;

    --titleColor : #922d66;
    --secondaryBgColor: #000;
    --lightBgColor: #383b40;
    --darkBgColor: #3c434b;
    --homeBgColor:#ffffff;
    --appBg:#fff;
    --bgHeaderMenucolor:#257CBC;
    --activeColor:#1b1f2a;
    --borderActiveColor: #1b1f2a;
    --homeTabs: #3c434b;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : black;
      --mainTabsColor: #ffffff;
  --displaytabs: flex;
    --sidebardetailscolor: #000;
    --LoveColor:#f4323d;
    --gamename:#000;
    --unSubscribeColor: #000;

    --gameBgColor : #ffffff;
    --gameMargin : 0;
    --colorActive : #fff;
    --profileTextColor : #000;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;

    --gameBorderradius : 0;
    --borderRadiusSize : 22px;
    --background-size: 'contain';
    --Allcolor: #922d66;
    --imgBorder : 22%;
    --tabMargin :0;
    --gameBorderB :'none';
    --gamePaddingB : 0;
    --gameMarginTop : 0;
    --backgroundcat :#257CBC;
    --activeBackground: #257CBC;
    --gameDetails : #787878;
    --gameDetailsType : #787878;
    --loginLogoWidth : 350px;
    --navbarLogoWidth : 240px;
    --linkGamemargin : 20px 3px 5px 15px;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    // --loginLogo: url(../assets/images/logos/ooredoo/Oredoopslogonav.png);
    // --navbarLogo: url(../assets/images/logos/ooredoo/Oredoopslogonav.png);
    // --leaderboard : url(../assets/images/leaderboard/bg-skwuit.svg);
    // --menuBg:url(../assets/icons/burger-menu.svg);
    // --searchBg:url(../assets/icons/search.svg);

    --loginLogo: url(../assets/images/logos/mauritania/maur-logo.png);
    --SecondLoginLogo: url(../assets/images/logos/mauritania/maur-secondLogo.png);
    --navbarLogo: url(../assets/images/logos/default/playit.svg);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --play : url(../assets/images/logos/mauritania/text.png);
  
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);
    --EnfontFamily : 'Rubik-Light';
    --EnHeadlinefontFamily: 'Outfit-Thin';

    --ArfontFamily : 'Noto-Kufi-Arabic';
    --ArHeadlinefontFamily: 'Noto-Kufi-Arabic';



  }