.containerComp {
  background: var(--homepageBgCompetition);
  background-size: cover; }

.compbuttons {
  display: flex;
  flex-direction: row;
  border: 1px solid red; }

.compbuttons > div {
  flex: 50%;
  border: 1px solid green; }

.leaderboard {
  background: var(--leaderboard-icon);
  background-repeat: no-repeat;
  background-size: cover;
  float: left; }

.tos {
  background: var(--help-icon);
  background-repeat: no-repeat;
  background-size: cover;
  float: right; }

.compbtn button {
  width: 70px;
  height: 70px;
  cursor: pointer;
  border: none;
  outline: none; }

.moreGame {
  width: 260px;
  height: 90px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  background-color: transparent; }

.compHomeGames {
  width: 56% !important;
  margin-top: 2px !important;
  flex-wrap: wrap; }

.btnspage {
  min-height: 85px;
  width: 56%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.btnhomeramdan {
  background: url("../../assets/images/HomepagecomGamezroid/homebtbg.png") no-repeat center center;
  background-size: contain;
  color: var(--textColor);
  padding: 10px;
  height: 69px;
  line-height: 9px;
  width: 110px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-family: SourceSansPro-Bold;
  transition: all ease 0.4s; }

.btnhomeramdan p {
  margin-top: 11px; }

.imgGame img {
  width: 100px;
  height: 100px; }

.gameCompName {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  color: #fff;
  padding: 2px 7px 9px 7px;
  text-transform: uppercase; }

.coins {
  width: 100px;
  height: 75px;
  position: absolute;
  top: 22%;
  left: 24%; }

body[dir='rtl'] .btnhomeramdan {
  font-size: 20px; }

@media (max-width: 1024px) {
  .compHomeGames {
    width: 61% !important; }
  .coins {
    left: -26px; } }

@media (max-width: 950px) {
  .compHomeGames {
    width: 30% !important; }
  .containerComp {
    min-height: 113vh !important;
    background-size: cover; } }

@media (max-width: 768px) {
  .btnspage {
    width: 62%; }
  .compbtn {
    width: 65% !important; }
  .spaceTop {
    height: 3vh !important; }
  .compHomeGames {
    width: 65% !important; }
  .containerComp {
    min-height: 113vh !important;
    background-size: cover; }
  .imgGame img {
    width: 90px;
    height: 90px; }
  .section-home {
    width: 72% !important; } }

@media (max-width: 540px) {
  .btnspage {
    width: 75%; }
  .containerComp {
    height: 719px !important;
    background-size: cover; }
  .compHomeGames {
    width: 77% !important; }
  .spaceTop {
    height: 7vh !important; }
  .section-home {
    width: 86% !important; }
  .compbtn {
    width: 80% !important; } }

@media (max-width: 418px) {
  .btnspage {
    width: 100%; }
  .containerComp {
    min-height: 112vh;
    background-size: cover; }
  .compbtn {
    width: 100% !important; }
  .spaceTop {
    height: 6vh !important; } }

@media (max-width: 418px) {
  .containerComp {
    height: 873px !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; } }

@media (max-width: 411px) {
  .spaceTop {
    height: 2vh !important; }
  .containerComp {
    background-size: cover; } }

@media (max-width: 400px) {
  .containerComp {
    height: 838px !important;
    background-size: cover; } }

@media (max-width: 376px) {
  .containerComp {
    height: 814px !important;
    min-height: 108vh !important;
    background-size: cover; }
  .coins {
    top: 102px; } }

@media (max-width: 360px) {
  .spaceTop {
    height: 5vh !important; }
  .coins {
    top: 120px; } }

@media (max-width: 320px) {
  .links-SectionCompetition {
    width: 98% !important; }
  .containerComp {
    height: 567px !important;
    min-height: 130vh !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; }
  .imgGame img {
    width: 75px;
    height: 75px; }
  .coins {
    top: 100px;
    left: -3px; }
  .btnhomeramdan {
    width: 100px; }
  .headerCompetion {
    margin-top: 0 !important; } }

@media (max-width: 280px) {
  .containerComp {
    height: 651px !important;
    min-height: 114vh !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; }
  .links-SectionCompetition {
    width: 100% !important; }
  .logoComp {
    width: 130px !important; } }

@media (max-device-width: 1024px) and (orientation: landscape) {
  .containerComp {
    min-height: 109vh !important; } }

@media (max-device-width: 993px) and (orientation: landscape) {
  .containerComp {
    min-height: 170vh !important; } }

@media (max-device-width: 882px) and (orientation: landscape) {
  .containerComp {
    min-height: 162vh !important; } }

@media (max-device-width: 823px) and (orientation: landscape) {
  .containerComp {
    min-height: 172vh !important; } }

@media (max-device-width: 768px) and (orientation: landscape) {
  .containerComp {
    min-height: 184vh !important; } }

@media (max-device-width: 640px) and (orientation: landscape) {
  .spaceTop {
    height: 4vh !important; }
  .containerComp {
    min-height: 162vh !important; } }

@media (max-device-width: 653px) and (orientation: landscape) {
  .spaceTop {
    height: 0 !important; }
  /*.containerComp {
        min-height: 226vh !important;
    }*/ }

.ice {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 200;
  overflow: hidden;
  pointer-events: none; }

.g-snows {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0; }

.g-snows > li {
  opacity: 0;
  position: absolute;
  top: 0;
  border-radius: 100%;
  background: url("../../assets/images/competition/partical1.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  animation-name: snow-drop;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards; }
  .g-snows > li:nth-child(1) {
    left: 42%;
    width: 26px;
    height: 26px;
    animation-duration: 6426ms;
    animation-delay: 624ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(2) {
    left: 48%;
    width: 21px;
    height: 21px;
    animation-duration: 6863ms;
    animation-delay: 4429ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(3) {
    left: 32%;
    width: 2px;
    height: 2px;
    animation-duration: 7975ms;
    animation-delay: 939ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(4) {
    left: 138%;
    width: 11px;
    height: 11px;
    animation-duration: 11356ms;
    animation-delay: 1511ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(5) {
    left: 66%;
    width: 0px;
    height: 0px;
    animation-duration: 5546ms;
    animation-delay: 207ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(6) {
    left: 8%;
    width: 12px;
    height: 12px;
    animation-duration: 9249ms;
    animation-delay: 3486ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(7) {
    left: 111%;
    width: 10px;
    height: 10px;
    animation-duration: 11212ms;
    animation-delay: 462ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(8) {
    left: 29%;
    width: 14px;
    height: 14px;
    animation-duration: 10414ms;
    animation-delay: 2573ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(9) {
    left: 38%;
    width: 4px;
    height: 4px;
    animation-duration: 9874ms;
    animation-delay: 628ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(10) {
    left: 13%;
    width: 8px;
    height: 8px;
    animation-duration: 5724ms;
    animation-delay: 4504ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(11) {
    left: 106%;
    width: 2px;
    height: 2px;
    animation-duration: 7051ms;
    animation-delay: 1812ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(12) {
    left: 78%;
    width: 4px;
    height: 4px;
    animation-duration: 5294ms;
    animation-delay: 1095ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(13) {
    left: 60%;
    width: 15px;
    height: 15px;
    animation-duration: 14419ms;
    animation-delay: 539ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(14) {
    left: 21%;
    width: 5px;
    height: 5px;
    animation-duration: 6261ms;
    animation-delay: 2631ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(15) {
    left: 12%;
    width: 6px;
    height: 6px;
    animation-duration: 5843ms;
    animation-delay: 3778ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(16) {
    left: 16%;
    width: 4px;
    height: 4px;
    animation-duration: 5097ms;
    animation-delay: 202ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(17) {
    left: 74%;
    width: 3px;
    height: 3px;
    animation-duration: 7093ms;
    animation-delay: 3851ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(18) {
    left: 57%;
    width: 9px;
    height: 9px;
    animation-duration: 8108ms;
    animation-delay: 3426ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(19) {
    left: 20%;
    width: 4px;
    height: 4px;
    animation-duration: 8821ms;
    animation-delay: 3867ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(20) {
    left: 68%;
    width: 10px;
    height: 10px;
    animation-duration: 6479ms;
    animation-delay: 334ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(21) {
    left: 37%;
    width: 12px;
    height: 12px;
    animation-duration: 8553ms;
    animation-delay: 1413ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(22) {
    left: 10%;
    width: 17px;
    height: 17px;
    animation-duration: 7831ms;
    animation-delay: 3098ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(23) {
    left: 68%;
    width: 2px;
    height: 2px;
    animation-duration: 7906ms;
    animation-delay: 4089ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(24) {
    left: 61%;
    width: 8px;
    height: 8px;
    animation-duration: 13845ms;
    animation-delay: 3030ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(25) {
    left: 14%;
    width: 6px;
    height: 6px;
    animation-duration: 5381ms;
    animation-delay: 1580ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(26) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-duration: 5879ms;
    animation-delay: 4833ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(27) {
    left: 79%;
    width: 13px;
    height: 13px;
    animation-duration: 6392ms;
    animation-delay: 2350ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(28) {
    left: 28%;
    width: 13px;
    height: 13px;
    animation-duration: 8231ms;
    animation-delay: 2916ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(29) {
    left: 18%;
    width: 13px;
    height: 13px;
    animation-duration: 9419ms;
    animation-delay: 150ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(30) {
    left: 75%;
    width: 12px;
    height: 12px;
    animation-duration: 5671ms;
    animation-delay: 1912ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(31) {
    left: 98%;
    width: 20px;
    height: 20px;
    animation-duration: 8362ms;
    animation-delay: 2458ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(32) {
    left: 41%;
    width: 5px;
    height: 5px;
    animation-duration: 8496ms;
    animation-delay: 2824ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(33) {
    left: 43%;
    width: 4px;
    height: 4px;
    animation-duration: 5522ms;
    animation-delay: 4458ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(34) {
    left: 144%;
    width: 20px;
    height: 20px;
    animation-duration: 6114ms;
    animation-delay: 638ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(35) {
    left: 43%;
    width: 12px;
    height: 12px;
    animation-duration: 10591ms;
    animation-delay: 2010ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(36) {
    left: 61%;
    width: 9px;
    height: 9px;
    animation-duration: 9380ms;
    animation-delay: 1088ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(37) {
    left: 9%;
    width: 2px;
    height: 2px;
    animation-duration: 10570ms;
    animation-delay: 554ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(38) {
    left: 30%;
    width: 14px;
    height: 14px;
    animation-duration: 13391ms;
    animation-delay: 3071ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(39) {
    left: 1%;
    width: 23px;
    height: 23px;
    animation-duration: 9670ms;
    animation-delay: 4858ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(40) {
    left: 48%;
    width: 27px;
    height: 27px;
    animation-duration: 14509ms;
    animation-delay: 4853ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(41) {
    left: 26%;
    width: 5px;
    height: 5px;
    animation-duration: 6913ms;
    animation-delay: 2820ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(42) {
    left: 12%;
    width: 18px;
    height: 18px;
    animation-duration: 6571ms;
    animation-delay: 4462ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(43) {
    left: 24%;
    width: 2px;
    height: 2px;
    animation-duration: 8771ms;
    animation-delay: 1401ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(44) {
    left: 58%;
    width: 15px;
    height: 15px;
    animation-duration: 7197ms;
    animation-delay: 2075ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(45) {
    left: 68%;
    width: 23px;
    height: 23px;
    animation-duration: 7908ms;
    animation-delay: 2081ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(46) {
    left: 119%;
    width: 15px;
    height: 15px;
    animation-duration: 7899ms;
    animation-delay: 1724ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(47) {
    left: 69%;
    width: 18px;
    height: 18px;
    animation-duration: 9536ms;
    animation-delay: 1945ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(48) {
    left: 57%;
    width: 2px;
    height: 2px;
    animation-duration: 9521ms;
    animation-delay: 4104ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(49) {
    left: 61%;
    width: 0px;
    height: 0px;
    animation-duration: 14129ms;
    animation-delay: 3490ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(50) {
    left: 101%;
    width: 3px;
    height: 3px;
    animation-duration: 7744ms;
    animation-delay: 2981ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(51) {
    left: 141%;
    width: 4px;
    height: 4px;
    animation-duration: 9460ms;
    animation-delay: 2077ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(52) {
    left: 79%;
    width: 1px;
    height: 1px;
    animation-duration: 14271ms;
    animation-delay: 190ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(53) {
    left: 15%;
    width: 13px;
    height: 13px;
    animation-duration: 6926ms;
    animation-delay: 4274ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(54) {
    left: 70%;
    width: 3px;
    height: 3px;
    animation-duration: 12079ms;
    animation-delay: 1463ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(55) {
    left: 42%;
    width: 25px;
    height: 25px;
    animation-duration: 10304ms;
    animation-delay: 1723ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(56) {
    left: 54%;
    width: 8px;
    height: 8px;
    animation-duration: 8073ms;
    animation-delay: 3215ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(57) {
    left: 77%;
    width: 24px;
    height: 24px;
    animation-duration: 6410ms;
    animation-delay: 4511ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(58) {
    left: 6%;
    width: 15px;
    height: 15px;
    animation-duration: 10563ms;
    animation-delay: 3919ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(59) {
    left: 82%;
    width: 27px;
    height: 27px;
    animation-duration: 8932ms;
    animation-delay: 1916ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(60) {
    left: 19%;
    width: 2px;
    height: 2px;
    animation-duration: 9101ms;
    animation-delay: 1213ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(61) {
    left: 49%;
    width: 2px;
    height: 2px;
    animation-duration: 6993ms;
    animation-delay: 3196ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(62) {
    left: 27%;
    width: 17px;
    height: 17px;
    animation-duration: 5957ms;
    animation-delay: 1627ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(63) {
    left: 71%;
    width: 9px;
    height: 9px;
    animation-duration: 13183ms;
    animation-delay: 4752ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(64) {
    left: 125%;
    width: 6px;
    height: 6px;
    animation-duration: 7234ms;
    animation-delay: 1531ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(65) {
    left: 8%;
    width: 0px;
    height: 0px;
    animation-duration: 6172ms;
    animation-delay: 3633ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(66) {
    left: 21%;
    width: 1px;
    height: 1px;
    animation-duration: 9075ms;
    animation-delay: 3757ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(67) {
    left: 36%;
    width: 1px;
    height: 1px;
    animation-duration: 12593ms;
    animation-delay: 1121ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(68) {
    left: 56%;
    width: 3px;
    height: 3px;
    animation-duration: 6619ms;
    animation-delay: 3490ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(69) {
    left: 22%;
    width: 16px;
    height: 16px;
    animation-duration: 5423ms;
    animation-delay: 1191ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(70) {
    left: 49%;
    width: 14px;
    height: 14px;
    animation-duration: 11334ms;
    animation-delay: 334ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(71) {
    left: 125%;
    width: 5px;
    height: 5px;
    animation-duration: 10195ms;
    animation-delay: 3452ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(72) {
    left: 63%;
    width: 1px;
    height: 1px;
    animation-duration: 8803ms;
    animation-delay: 4544ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(73) {
    left: 29%;
    width: 8px;
    height: 8px;
    animation-duration: 10294ms;
    animation-delay: 1013ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(74) {
    left: 20%;
    width: 2px;
    height: 2px;
    animation-duration: 14125ms;
    animation-delay: 392ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(75) {
    left: 80%;
    width: 5px;
    height: 5px;
    animation-duration: 12627ms;
    animation-delay: 2331ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(76) {
    left: 52%;
    width: 4px;
    height: 4px;
    animation-duration: 7014ms;
    animation-delay: 4938ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(77) {
    left: 110%;
    width: 14px;
    height: 14px;
    animation-duration: 6875ms;
    animation-delay: 392ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(78) {
    left: 88%;
    width: 9px;
    height: 9px;
    animation-duration: 9371ms;
    animation-delay: 458ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(79) {
    left: 97%;
    width: 14px;
    height: 14px;
    animation-duration: 8501ms;
    animation-delay: 791ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(80) {
    left: 43%;
    width: 3px;
    height: 3px;
    animation-duration: 10391ms;
    animation-delay: 3271ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(81) {
    left: 18%;
    width: 5px;
    height: 5px;
    animation-duration: 10108ms;
    animation-delay: 1521ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(82) {
    left: 52%;
    width: 18px;
    height: 18px;
    animation-duration: 9549ms;
    animation-delay: 639ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(83) {
    left: 18%;
    width: 18px;
    height: 18px;
    animation-duration: 11891ms;
    animation-delay: 3437ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(84) {
    left: 54%;
    width: 12px;
    height: 12px;
    animation-duration: 7452ms;
    animation-delay: 2310ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(85) {
    left: 133%;
    width: 12px;
    height: 12px;
    animation-duration: 5605ms;
    animation-delay: 2171ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(86) {
    left: 80%;
    width: 3px;
    height: 3px;
    animation-duration: 7720ms;
    animation-delay: 2914ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(87) {
    left: 55%;
    width: 3px;
    height: 3px;
    animation-duration: 10804ms;
    animation-delay: 202ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(88) {
    left: 74%;
    width: 5px;
    height: 5px;
    animation-duration: 11169ms;
    animation-delay: 3690ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(89) {
    left: 31%;
    width: 24px;
    height: 24px;
    animation-duration: 10096ms;
    animation-delay: 3229ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(90) {
    left: 7%;
    width: 2px;
    height: 2px;
    animation-duration: 10300ms;
    animation-delay: 3764ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(91) {
    left: 21%;
    width: 11px;
    height: 11px;
    animation-duration: 5446ms;
    animation-delay: 833ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(92) {
    left: 90%;
    width: 24px;
    height: 24px;
    animation-duration: 6761ms;
    animation-delay: 4233ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(93) {
    left: 40%;
    width: 1px;
    height: 1px;
    animation-duration: 9717ms;
    animation-delay: 4966ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(94) {
    left: 41%;
    width: 1px;
    height: 1px;
    animation-duration: 11738ms;
    animation-delay: 4193ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(95) {
    left: 46%;
    width: 8px;
    height: 8px;
    animation-duration: 6065ms;
    animation-delay: 1310ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(96) {
    left: 2%;
    width: 12px;
    height: 12px;
    animation-duration: 5947ms;
    animation-delay: 1114ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(97) {
    left: 44%;
    width: 16px;
    height: 16px;
    animation-duration: 6271ms;
    animation-delay: 1299ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(98) {
    left: 73%;
    width: 24px;
    height: 24px;
    animation-duration: 6216ms;
    animation-delay: 3596ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(99) {
    left: 65%;
    width: 7px;
    height: 7px;
    animation-duration: 8472ms;
    animation-delay: 2606ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(100) {
    left: 2%;
    width: 5px;
    height: 5px;
    animation-duration: 5843ms;
    animation-delay: 1823ms;
    filter: blur(1px); }

@keyframes snow-drop {
  0% {
    transform: translate(0, 0);
    opacity: 0.5;
    margin-left: 0; }
  10% {
    margin-left: 15px; }
  20% {
    margin-left: 20px; }
  25% {
    transform: translate(0, 166.66667px);
    opacity: 0.75; }
  30% {
    margin-left: 15px; }
  40% {
    margin-left: 0; }
  50% {
    transform: translate(0, 333.33333px);
    opacity: 1;
    margin-left: -15px; }
  60% {
    margin-left: -20px; }
  70% {
    margin-left: -15px; }
  75% {
    transform: translate(0, 500px);
    opacity: 0.5; }
  80% {
    margin-left: 0; }
  100% {
    transform: translate(0, 666.66667px);
    opacity: 0; } }
