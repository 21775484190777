
.rankingHeader{
    background: var(--leaderboard) center center no-repeat;
    height: 211px ;
    background-size: cover;
}
.smallPlate{
    background: url("../../assets/images/leaderboard/sm-plate.svg") no-repeat;
    height: 25px;
    width:65px;
    padding: 10px 0px;

}
.bigPlate{
    background: url("../../assets/images/leaderboard/lg-plate.svg") no-repeat;
    height: 12px;
    width:127px;
    padding: 30px 0px;
}
.secondFrame, .firstFrame{
    padding-bottom:10px;
}
.firstFrame ,.secondFrame ,.thirdFrame{
    height: 39px;
    width:39px;
    position: absolute;
    bottom: 85px;
}
.secondFrame{
    background: url("../../assets/images/leaderboard/2nd-frame.svg") no-repeat;
}
.firstFrame{
    background: url("../../assets/images/leaderboard/1st-frame.svg") no-repeat;
    height:61px;
    width:55px;
    bottom: 105px;
}
.thirdFrame{
    background: url("../../assets/images/leaderboard/3rd-frame.svg") no-repeat;
    padding-bottom: 11px;
}
body[dir="rtl"] {
    .thumb .xsAvatar {
    height: 36px;
    width: 37px;
    }
}
.name, .points
{
 color: var(--sidebardetailscolor)

}