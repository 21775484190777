.ncellTos {
  font-size: 13px;
  margin-top: 20px;
}

.links-Section{
  position: absolute;
    top: 30%;
}

// .play
// {
//   height: 100px;
// }
.formsLayoutniger
{
}
@media only screen and (max-width: 320px) {

  .formsLayoutniger
  {
    //background-color: red;
    min-height: 110vh!important;

  }
  
}


// @media only screen and (max-width: 414px) {

//   .formsLayoutniger
//   {
//     //background-color: red;
//     min-height: 100vh!important;

//   }
  
// }


// @media only screen and (max-width: 410px) {

//   .formsLayoutniger
//   {
//     //background-color: red;
//     min-height: 84vh!important;

//   }
  
// }


// @media only screen and (max-width: 360px) {

//   .formsLayoutniger
//   {
//     //background-color: red;
//     min-height: 100vh!important;

//   }
  
// }




  
  