.linkscontent
{
  // width: 100%;
  // margin: auto;
  // position: absolute;
  // top: 338px;
  // color: black;
  // //background-color: #fff;

  display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;


}
.sharepen , .copylink
{
  display: flex;
    justify-content: center;
    padding: 5px 20px;

}
.copylink
{
  justify-content: space-between;


}
.contents
{
  width: 33%;
  margin: auto;
  // background-color: #fff;
 background: url("../../assets/images/HomepagecomGamezroid/sharetest.svg") no-repeat center center;
  // border: 3px solid #fff;
    border-radius: 15px;
    background-size: cover;
    height: 339px;
}

.sharelinkbtn
{
  //background: url("../../assets/images/HomepagecomGamezroid/linkbtbfg.png") no-repeat center center!important;
  background-color: white!important;
  width: 135px!important;
  color: black;
    padding: 10px;
    min-height: 50px;
    border: 1px solid transparent;
    border-radius: 15px;
    cursor: pointer;
    outline: none;
    font-size: 20px;
    font-family: SourceSansPro-Bold;
    transition: all ease 0.4s;
    direction: rtl;


}
.sharelinkbtn img 
{
 
  margin-right: 10px;
    height: 25px;

}
.sharelinkbtn span{
  color: black!important;
  font-family: serif;
  font-size: 16px;
}

.linksshare
{
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: space-around;
    height: 220px;
    align-items: center;
}


@media(max-width: 1024px)
{
  .contents {
    width: 39%
  }

  .freepoints 
  {
    margin-top: 171px!important;
  }
  

}


@media(max-width: 768px)
{
  .contents {
    width: 50%
  }

  .freepoints 
  {
    margin-top: 85px!important;
  }
  

}

@media(max-width: 540px)
{
  .contents {
    width: 68%;
}
  

}

@media(max-width: 415px)
{
  .contents {
    width: 88%;
}
  

}





@media(max-width: 320px)
{
  .freepoints {
    width: 95%!important;
  }
  .sound
  {
    width: 274px;
  }

}

@media(max-width: 380px)
{
  .freebtn
  {
    width: 70%!important;
  }

  .contents {
    width: 99%;
}

}
@media(max-width: 320px)
{
  .freebtn
  {
    width: 90%!important;
  }

}

body[dir="rtl"]
{

  .sharelinkbtn img 
{
  // margin-left: 10px;
  // margin-right: 0;
}

.sharelinkbtn
{
  //direction: ltr;
}
}