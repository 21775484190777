html[data-theme="zainiq"] {
    --primaryColor: hsl(151deg 74% 62%);
    --borderActiveTab : hsl(151deg 74% 62%);
    --activebgTaps : hsl(151deg 74% 62%);

    --titleColor :  hsl(257deg 96% 69%);
    --secondaryBgColor: #333;
    --BgNavScroll : #333;

    --lightBgColor: #383b40;
    --darkBgColor: #1b1f2a;
    --homeBgColor:#333;
    --appBg:#333;
    --bgHeaderMenucolor:hsl(151deg 74% 62%);
    --activeColor:#12151d;
    --borderActiveColor: hsl(151deg 74% 62%);
    --homeTabs: #434242;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : #ffffff;
    --mainTabsColor: #fff;
    --sidebardetailscolor: #ffffff;
    --LoveColor:#f4323d;
    --gamename:#fff;
    --unSubscribeColor: #fff;

    --borderRadiusSize : 22px;
    --background-size: contain;
    --Allcolor: #fff;
    --profileTextColor : #fff;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;

    --backgroundcat : hsl(257deg 96% 69%);
    --activeBackground: hsl(151deg 74% 62%);
    --gameDetailsType : rgb(120, 120, 120);
    --loginLogoWidth : 180px;
    --navbarLogoWidth : 240px;
    --gameBgColor : #333;
    --gameBorderradius : 6px;
    --gameMargin : 0;
    --imgBorder : 22%;
    --tabMargin :25px;
    --linkGamemargin : 20px 3px 5px 15px;
    //--linkGamemargin : 20px 0px 20px 20px;
    --gameBorderB :1px solid #ffffff;
    --gameMarginTop : 0;
    --colorActive : #fff;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    // --loginLogo: url(../assets/images/logos/default/playit-with-text.svg);
    // --navbarLogo: url(../assets/images/logos/default/playit.svg);
    // --leaderboard : url(../assets/images/leaderboard/bg.svg);
    // --paymentLogo : url(../assets/images/logos/default/fawry_logo.svg);
    // --play : url(../assets/images/logos/gamezroid/play.png);
    // --exclusiveGame : url(../assets/images/background/EX.png);
    // --exclusiveGamePart2 : url(../assets/images/background/EX.png);
    // --competitionBg : url(../assets/images/competetion-new.jpg);
    // --menuBg:url(../assets/icons/burger-menu.svg);
    // --searchBg:url(../assets/icons/search.svg);

    --loginLogo: url(../assets/images/logos/gamezroid/loginLogo.png);
    --navbarLogo: url(../assets/images/logos/gamezroid/navLogo.png);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --subscribe: url(../assets/images/background/subscribe.jpg);
    --play : url(../assets/images/logos/gamezroid/play.png);
    --games_icon : url(../assets/images/logos/gamezroid/games.png);
    --homepageBgCompetition : url(../assets/images/bg-banners/home-competition.jpg);
    --leaderboard-icon: url(../assets/images/HomepagecomGamezroid/leader.png);
    --help-icon: url(../assets/images/HomepagecomGamezroid/help.png);
    --competitionLogo: url(../assets/images/HomepagecomGamezroid/logo1.png);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);
     }