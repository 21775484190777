.load {
    width: 100%;
    position: absolute;
    z-index: 145;
    top: 0;
    background: black;
    opacity: 0.5;
}
.headerSignupMtn
{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0!important;
    padding: 66px 0;
    // background-image:  url("../../assets/images/mtn/bg.png")!important;
   // height: 50vh;
   background-color: #ffcb05!important;

  background-color: var(--primary-color);
//   background-repeat: no-repeat;
//   background-size: cover;
}

.footerMtn {
    margin-bottom: 22px !important;
    .ncellTosmtn p {
        font-weight: 200;
        margin: 5px;
        //font-family: 'Droid Arabic Kufi',sans-serif!important;
    }
}