.scratchVoucher {
    background: url("../../assets/images/voucher.svg") center center no-repeat;
    height: 175px;
   
}
.homeModel{
    background-image: url("../../assets/images/modelAds.jpg");
    background-size: 100% 100%;
    height: 100%;
    max-width: 341px;
}
.egy{
    background-image: url("../../assets/images/egyModel.jpg") !important; 
}
.egy a {
    color: #ffffff !important;
}
.homeModel a,.instModel a{
    color: #ffffff00;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ff000000;
    padding: 7px;
    border-radius: 50%;
    width: 35px;
    height: 34px;
    
}

.instModel{
    background-image: url("../../assets/images/instModelImg.jpg") !important;
    background-size: 100% 100%;
    height: 100%;
    max-width: 375px;
}

.homebg
{
    background: url("../../assets//images/home.jpg");
}

body[dir="rtl"] {

    
    //   @media(max-width: 768px)
    //   {
    //       .onlinesubTabs
    //       {
    //         width : 93%
    //       }
    //   }
  
    
.subTabs {
   // direction:ltr
}

  }
