html[data-theme="africell"] {
    --primaryColor: #A01775;
    --borderActiveTab : #A01775;
    --activebgTaps : #A01775;

    --titleColor : #ffffff;
    --secondaryBgColor: #12151d;
    --BgNavScroll : #12151d;

    --lightBgColor: #383b40; 
    --darkBgColor: #1a1f2a;
    --homeBgColor:#19181C;
    --appBg:#19181C;
    --bgHeaderMenucolor:#A01775;
    --activeColor:black;
    --borderActiveColor: #A01775;
    --homeTabs: #27313b;
    --textColor:#ffffff;
    --subBorderColor:#fff;

    --paymentTextcolor : #ffffff;
      --mainTabsColor: #ffffff;
  --displaytabs: flex;
    --sidebardetailscolor: #ffffff;
    --LoveColor:#A01775;
    --gameBgColor : #19181C;
    --gameBorderradius : 0;
    --gameMargin : 0;
    --colorActive : #fff;
    --profileTextColor : #fff;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;
    --gamename:#ffffff;
    --unSubscribeColor: #fff;
    --borderRadiusSize : 22px;
    --background-size: 'contain';
    --Allcolor: #A01775;  
    --gameDetailsType : rgb(120, 120, 120);
    --loginLogoWidth : 350px;
    --backgroundcat : #A01775;
    --activeBackground: #A01775;
    --imgBorder : 0;
    --gameMarginTop : 0;
    --tabMargin :0;
    --gameBorderB :1px solid #A01775;
    --gamePaddingB : 0;
    --linkGamemargin : 20px 3px 5px 15px;
    --navbarLogoWidth : 240px;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --loginLogo: url(../assets/images/logos/africell/loginLogo.png);
    --navbarLogo: url(../assets/images/logos/africell/navLogo.png);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);

  }