.btn {
  background: var(--primaryColor);
  color: var(--textColor);
  padding: 10px;
  min-height: 50px;
  min-width: 230px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  font-size: 20px;
  font-family: SourceSansPro-Bold;
  transition: all ease 0.4s;
  /*font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  margin-left: 24px;*/

  //margin-bottom: 45px!important ;

}
.vod
{
  margin-top: 70px;
  background: var(--vodColor);
}
.org
{
  background: var(--orgColor);
}

.sabafon
{
  background: var(--sabafonColor);
}
.ets
{
  background: var(--etsColor);
}
.ooredoo
{
  background: var(--ooredoocolor);
}
.zainjordan
{
  background: var(--zainjordancolor);
}

.gamezroid
{
  background: var(--gamezroidcolor);
  margin: 20px auto;
  color: black;
  border: 1px solid #bb2f0c;
  // padding: 25px;
  border-radius: 32px;
  width: 88%;
  max-width: 280px;

}
.mob
{
  margin-top: 70px;
  background: var(--mobColor);
}
.btngamezone
{
  background-color: #65378b;
}
.stc
{
  background: var(--stcColor);
}
.zain
{
  background: var(--zainColor);
}
.btn.secondary {
  color: var(--textColor);
  background: #bcbcbc;
}
.normBtn {
  margin: 35px;
}
.playBtn {
  position: relative;
  display: inline-block;
  margin: 20px;
  cursor: pointer;

  .icon {
    position: absolute;
    width: 70px;
    height: 70px;
    display: inline-block;
    background: url("../icons/play.svg") center center no-repeat;
    background-size: 60px;
    top: -8px;
    left: -5px;
  }
  .btn {
    padding: 0 0 0 50px;
    min-width: 180px;
  }
}
.btn.play {
  margin: 22px;
  padding: 0;
  overflow: visible;
  display: flex;
  .txt {
    display: inline-block;
  }
}
.btn:disabled,
.btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.socialBtn {
  width: 56px;
  height: 56px;
  border: solid 1px transparent;
  outline: none;
  margin: 20px;
  cursor: pointer;
}
.socialBtn.google {
  background: url("../icons/forms/google.svg") center center no-repeat;
}
.socialBtn.facebook {
  background: url("../icons/forms/facebook.svg") center center no-repeat;
}
.socialBtn.twitter {
  background: url("../icons/forms/twitter.svg") center center no-repeat;
}

body[dir="rtl"] {
  .btn {
    font-family: droid-Kufi-bold;
  }
}
