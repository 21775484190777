.titel{
    text-align: center;
    color: #009edd;
}
.content
{
    color: var(--sidebardetailscolor);
}
.titel_header
{
    color:var(--primaryColor);
}
