html[data-theme="gamezroidRamadan"] {
    --primaryColor:#009EDD;
    --titleColor : #ffffffe6;
    --secondaryBgColor: 'none';
    --lightBgColor: #383b40;
    --darkBgColor: #1b1f2a;
    --bgHeaderMenucolor:#051D4A;
    --homeBgColor:#fff;
    --activeColor:#004A8B;
    --borderActiveTab : #fff;
    --borderActiveColor: #00bcd4;
    --homeTabs: #051D4A;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : #ffffff;
    --mainTabsColor: #0090FF;
    --sidebardetailscolor: #ffffff;
    --LoveColor:#f4323d;
    --gamename: #ffffffa1;
    --unSubscribeColor: #ffffffa1;

    --profileTextColor : #fff;
    --borderRadiusSize : 24px;
    --background-size: 'contain';
    --Allcolor: #ffffffa1;
    --backgroundcat :#00adef;
    --colorActive : #fff;
    --activeBackground: #fff;
    --activebgTaps : #004A8B;
    --gameDetailsType : #ffffffa1;
    --gameDetails: #fff;
    --loginLogoWidth : 180px;
    // --navbarLogoWidth : 240px;
    --navbarLogoWidth : 200px;
    --navbarcomLogoWidth : 160px;
    --navSearchIconheight : 44px;
    --navSearchIconwidth : 41px;
    --navMenuIconheight : 44px;
    --navMenuIconwidth : 41px;
    --gameBgColor : '';
    --gameBorderradius : 6px;
    --gameMargin : 0;
    --imgBorder : 22%;
    --tabMargin :25px;
    --marginNav : 4px 0;
    --paddingTaps : 20px;
    --linkGamemargin : 20px 0 3px 20px;
    --marginScroll: 0;
    --BgNavScroll : #054566;
    --opacityScroll : 0.8;
    //--linkGamemargin : 20px 0px 20px 20px;
    --gameBorderB :1px solid #ffffffa1;
    --gameMarginTop : 0;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --loginLogo: url(../assets/images/logos/gamezroid/Z.png);
    --navbarLogo: url(../assets/images/logos/gamezroid/Z.png);
    --leaderboard : '';
    --subscribe: url(../assets/images/background/subscribe.jpg);
    --play : url(../assets/images/HomepagecomGamezroid/Main-Banner.png);
    --homepageBgCompetition : url(../assets/images/HomepagecomGamezroid/homebg.png);
    --leaderboard-icon: url(../assets/images/HomepagecomGamezroid/leader.png);
    --help-icon: url(../assets/images/HomepagecomGamezroid/help.png);
    --competitionLogo: url(../assets/images/HomepagecomGamezroid/logo1.png);
    --appBg:url(../assets/images/HomepagecomGamezroid/bg.png);
    --menuBg:url(../assets/images/HomepagecomGamezroid/menu.png);
    --searchBg:url(../assets/images/HomepagecomGamezroid/search.png);
    --insideBg:url(../assets/images/HomepagecomGamezroid/ramdanInside.png);
    --loginBg:url(../assets/images/HomepagecomGamezroid/ramdanInside.png);
     }