/*.rankingHeader {
    background: var(--leaderboard) center center no-repeat;
    height: var(--rankingHeader);
    background-size: cover;
}

.smallPlate {
    // background: url("../../assets/images/leaderboard/sm-plate.svg") no-repeat;
    height: 25px;
    width: 65px;
    padding: 10px 0px;
    font-size: 11px;
    margin-top: 23%;

}

.mdPlate {
    // background: url("../../assets/images/leaderboard/sm-plate.svg") no-repeat;
    height: 25px;
    width: 65px;
    padding: 10px 0px;
    font-size: 10px;
    margin-top: 22%;

}

.bigPlate {
    //background: url("../../assets/images/leaderboard/lg-plate.svg") no-repeat;
    height: 12px;
    width: 127px;
    padding: 30px 0px;
    font-size: 14px;
    margin-top: 37%;
}

.first {
    background: url("../../assets/images/leaderboard/users/1.png") no-repeat;
}

.second {
    background: url("../../assets/images/leaderboard/users/2.png") no-repeat;

}

.third {
    background: url("../../assets/images/leaderboard/users/3.png") no-repeat;

}

.secondFrame,
.firstFrame {
    padding-bottom: 10px;
}

.firstFrame,
.secondFrame,
.thirdFrame {
    height: 39px;
    width: 39px;
    position: absolute;
    bottom: 85px;
}

.secondFrame {
    background: url("../../assets/images/leaderboard/2nd-frame.svg") no-repeat;
}

.firstFrame {
    background: url("../../assets/images/leaderboard/1st-frame.svg") no-repeat;
    height: 61px;
    width: 55px;
    bottom: 122px;
}

.thirdFrame {
    background: url("../../assets/images/leaderboard/3rd-frame.svg") no-repeat;
    padding-bottom: 11px;
}

body[dir="rtl"] {
    .thumb .xsAvatar {
        height: 36px;
        width: 37px;
    }
}

.name,
.points {
    color: var(--sidebardetailscolor);

}

.name1 {
    position: absolute;

    bottom: 65%;

}

.name2 {
    position: absolute;

    bottom: 45%;

}

.name3 {
    position: absolute;

    bottom: 52%;

}

.rankingHeader .rank:nth-of-type(odd)
{
    background-color:#00294D;
    padding: 10px 0;

}
.rankingHeader .rank:nth-of-type(even)
{
    background-color: #003F64;
    padding: 8px 0;


}*/
.rankingHeader {
  background: var(--leaderboard) center center no-repeat;
  height: 211px;
  background-size: cover; }

.rankingCompetitionHeader {
  height: 295px; }

.headerComp {
  padding: 0 50px;
  min-height: 240px !important;
  margin-bottom: 22px; }

.smallPlate {
  background: url("../../assets/images/leaderboard/sm-plate.svg") no-repeat;
  height: 25px;
  width: 65px;
  padding: 10px 0px; }

.bigPlate {
  background: url("../../assets/images/leaderboard/lg-plate.svg") no-repeat;
  height: 12px;
  width: 127px;
  padding: 30px 0px; }

.smPlate {
  background: none;
  height: 25px;
  width: 65px;
  font-size: 11px;
  margin-top: 23%; }

.mdPlate {
  background: none;
  height: 25px;
  width: 65px;
  padding: 10px 0px;
  font-size: 10px;
  margin-top: 22%; }

.bgPlate {
  background: none;
  width: 127px;
  padding: 30px 0px;
  font-size: 14px;
  margin-top: 37%; }

.secondFrame, .firstFrame {
  padding-bottom: 10px; }

.firstFrame, .secondFrame, .thirdFrame {
  height: 39px;
  width: 39px;
  position: absolute;
  bottom: 85px; }

.secondFrame {
  background: url("../../assets/images/leaderboard/2nd-frame.svg") no-repeat; }

.firstFrame {
  background: url("../../assets/images/leaderboard/1st-frame.svg") no-repeat;
  height: 61px;
  width: 55px;
  bottom: 105px; }

.thirdFrame {
  background: url("../../assets/images/leaderboard/3rd-frame.svg") no-repeat; }

.CompetitionsecondFrame {
  background-repeat: no-repeat;
  bottom: 135px;
  background-size: contain;
  border-radius: 50%;
  padding-bottom: 0;
  height: 25px;
  width: 25px; }

.CompetitionfirstFrame {
  background-repeat: no-repeat;
  bottom: 185px;
  background-size: contain;
  border-radius: 50%;
  padding-bottom: 0;
  height: 40px;
  width: 40px; }

.CompetitionthirdFrame {
  background-repeat: no-repeat;
  bottom: 139px;
  background-size: contain;
  border-radius: 50%;
  padding-bottom: 0;
  height: 20px;
  width: 20px; }

body[dir="rtl"] .thumb .xsAvatar {
  height: 36px;
  width: 37px; }

.name, .points {
  color: var(--sidebardetailscolor); }

.name1 {
  position: absolute;
  bottom: 37%; }

.name2 {
  position: absolute;
  bottom: 26%; }

.name3 {
  position: absolute;
  bottom: 26%; }

.rankingHeader .rank:nth-of-type(odd) {
  background-color: #00294D;
  padding: 10px 0; }

.rankingHeader .rank:nth-of-type(even) {
  background-color: #003F64;
  padding: 8px 0; }

.first_competition {
  background: url("../../assets/images/leaderboard/users/1.png") no-repeat; }

.second_competition {
  background: url("../../assets/images/leaderboard/users/2.png") no-repeat; }

.third_competition {
  background: url("../../assets/images/leaderboard/users/3.png") no-repeat; }

.compGames {
  min-height: 200px;
  width: 25%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-around;
  -webkit-justify-content: space-around;
  -ms-flex-pack: space-around;
  justify-content: space-around;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.headercomgames {
  background-color: #001439;
  background: url("../../assets/images/leaderboard/bg1.png") no-repeat;
  background-position: center center; }

.gamecomp1 {
  width: 90px;
  height: 90px;
  background-size: contain;
  background: url("../../assets/images/leaderboard/g1.png") no-repeat;
  margin-top: 75%; }

.gamecomp2 {
  width: 90px;
  height: 90px;
  background-size: contain;
  background: url("../../assets/images/leaderboard/g2.png") no-repeat;
  margin-top: 75%; }

.rankBorder {
  border: 4px solid #d91b0e;
  /*box-shadow: 5px 10px red;
    box-shadow: 1px 10px 4px 14px #009edd;
    /* box-shadow: 0 4px 2px -2px #009edd; */ }

@media (max-width: 768px) {
  .compGames {
    width: 32%; } }

@media (max-width: 418px) {
  .compGames {
    width: 60%; } }

body[dir="rtl"] .points {
  direction: ltr !important; }
