html[data-theme="zainjordan"] {
    --primaryColor:#37ace5;
    --activebgTaps : #009edd;

    --borderActiveTab : #37ace5;

    --titleColor : #37ace5;
    --secondaryBgColor: #12151d;
    --lightBgColor: #383b40;
    --darkBgColor: #1b1f2a;
    --homeBgColor:#ffffff;
    --appBg:#ffffff;
    --bgHeaderMenucolor:#37ace5;
    --activeColor:#1b1f2a;
    --borderActiveColor: #1b1f2a;
    --homeTabs: #27313b;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : #ffffff;
      --mainTabsColor: #ffffff;
  --displaytabs: flex;
    --sidebardetailscolor: #12151d;
    --gamename:#12151d;
    --unSubscribeColor: #12151d;

    --LoveColor:#f4323d;
    --gameMargin : 0;
    --gameBgColor : #ffffff;
    --colorActive : #fff;
    --profileTextColor : #fff;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;

    --borderRadiusSize : 22px;
    --background-size: 'contain';
    --Allcolor: #37ace5;
    --linkGamemargin : 20px 3px 5px 15px;
    --imgBorder : 22%;
    --tabMargin :0;
    --gameBorderB :'none';
    --gamePaddingB : 0;
    --gameBorderradius : 0;
    --gameMarginTop : 0;
    --backgroundcat :#37ace5;
    --activeBackground: #37ace5;
    --loginLogoWidth : 350px;
    --gameDetailsType : #787878;
    --navbarLogoWidth : 240px;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --loginLogo: url(../assets/images/logos/zainjordan/Zain_play.png);
    --navbarLogo: url(../assets/images/logos/zainjordan/Zainlogonav.png);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);

  }