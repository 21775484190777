.loadingPlayit {
  height: 0;
  width: 0;
  padding: 10px;
  border: 4px solid var(--darkPrimaryColor);
  border-right-color: var(--primaryColor);
  border-radius: 22px;
  animation: rotate 0.4s infinite linear;
}

@keyframes rotate {
  /* 100% keyframe for  clockwise. 
       use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
