
.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 200px;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

.slide-container
  {
    margin: 0px 0;
  }