html[data-theme="gamezoneplayit"] {
    --primaryColor: #0645b1;
    --activebgTaps : #0645b1;
    --borderActiveTab : #0645b1;
    --titleColor : #0645b1;
    --activeColor:#0645b1;
    --borderActiveColor: #0645b1;
    --vodColor: #ff3030;
    --orgColor: #ff6e23;
    --etsColor: #008734;
    --mobColor: #0645b1;
    --stcColor: #613bff;
    --sabafonColor: #ff6e23;
    --zainColor: #3ef886;
    --ooredoocolor :#ff6e23;
    --zainjordancolor:#37ace5;
    --gamezroidcolor: #f2d12c;
    --darkPrimaryColor: #004f6f;
    --secondaryBgColor: #12151d;
    --BgNavScroll : #12151d;
    --loginLogoHeight : 51px;
    --lightBgColor: #333742;
    --darkBgColor: #1b1f2a;
    --homeBgColor:#fff;
    --appBg:#fff;
    --textColor: #ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : #ffffff;
      --mainTabsColor: #ffffff;
  --displaytabs: flex;
    --gameDetails : #27313b;
    --sidebardetailscolor: #27313b;
    --gamename:#27313b;
    --unSubscribeColor: #27313b;

    --sSubtabColor: #fff;
  
    --borderRadiusSize : 22px;
    --homeTabs: #27313b;
    --LoveColor: #f4323d;
    --gameBgColor : #fff;
    --gameBorderradius : 0;
    --gameMargin : 0;
    --background-size: 160px;
    --loginLogoWidth : 160px;
    --navbarLogoWidth : 160px;
    --navSearchIconheight : 17px;
      --navSearchIconwidth : 17px;
      --navMenuIconheight : 20px;
      --navMenuIconwidth : 25px;
    --gameDetailsType : rgb(120, 120, 120);
    --Allcolor: #0645b1;
    --backgroundcat :#0645b1;
    --activeBackground: #fff;
    --bgHeaderMenucolor:#0645b1;
    --linkGamemargin : 20px 3px 5px 15px;
    --imgBorder : 22%;
    --tabMargin :0;
    --gameBorderB :1px solid rgb(66, 69, 74);
    --gameMarginTop : 0;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --rankingHeader : 211px;
    --rankingHeadercontent : 200;
    --colorActive : #fff;
    --paddingTaps : 0;
    --marginScroll: 0 0;
    --opacityScroll : 'none';
    --profileTextColor : #fff;
    --loginLogo: url(../assets/images/logos/default/playit-with-text.svg);
    --navbarLogo: url(../assets/images/logos/default/playit.svg);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --paymentLogo : url(../assets/images/logos/default/fawry_logo.svg);
    --play : url(../assets/images/logos/gamezroid/play.png);
    --exclusiveGame : url(../assets/images/background/EX.png);
    --exclusiveGamePart2 : url(../assets/images/background/EX.png);
    --competitionBg : url(../assets/images/competetion-new.jpg);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);
    --insideBg:'';
    --loginBg:'';



     }