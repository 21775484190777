.load
{
    width: 100%;
    position: absolute;
    z-index: 145;
    top: 0;
    background: black;
    opacity: 0.5;
}

@media(max-width: 768px){
    .links-SectionUmniah
    {
        width: 90%!important;
    }
 
  }

  @media(max-width: 360px){
      .subplan
      {
          font-size: 13px;
      }
    // .links-SectionUmniah
    // {
    //     width: 95%!important;
    // }
 
  }