.playing {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background: var(--darkBgColor);
}
body[dir="rtl"] {
.gameFooter{
  flex-direction: row-reverse;
}
.gameData .titleDetails .title{
  text-align: right;
}


}



.gameIframe{
 
}

.iframe-container{
  height: 95vh;
  min-width: 100%;
  width: 100px;
  *width: 100%;
}
.iframe-container iframe{
  min-width: 100%;
  width: 100px;
  *width: 100%;
  min-height: 100%;
  height: 100px;
  *height: 100%;
  /* height: 100%; */
}
.iframe-container object{
  min-width: 100%;
  width: 100px;
  *width: 100%;
  min-height: 100%;
  height: 100px;
  *height: 100%;
  /* height: 100%; */
}

.thumb_images{
  max-width: 100%;
}

.thumb_images img{
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  margin: 5px;
  outline: none !important;
}
.gameBody{
  padding: 5px 25px 20px !important;
 

}
.desc{
  height: auto;
  transition: width 2s;
  transition-duration: 2s;
  transition: all 1s ease;

}
.descLess{
  
  height: 25px;
  overflow: hidden;
  margin-bottom: 10px;
  line-height: 1.5;
  opacity: 0.75;
  font-size: 14px;
  padding-bottom: 14px;
  transition: width 2s;
  transition: all 1s ease;

}
.seeP{
  text-align: left;
  line-height: 1.5;
  font-size: 14px;
  padding-bottom: 14px;
  margin: 0;
  color: var(--primaryColor);
  transition: all 1s ease;

}