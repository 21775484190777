.input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #31333b;
  height: 40px;
  width: calc(100% - 20px);
  outline: none;
  color: var(--textColor);
}
.input:focus {
  border-color: #999;
}
.iconInput {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 10px 0;

  .input {
    padding-left: 20px;
  }
  .icon {
    position: absolute;
    top: 12px;
    left: 0;
    width: 15px;
    height: 15px;
    background: center center no-repeat;
    &.phone {
      background-image: url("../icons/forms/phone.svg");
    }
    &.username {
      background-image: url("../icons/forms/user.svg");
    }
    &.email {
      background-image: url("../icons/forms/email.svg");
    }
    &.creditcard {
      background-image: url("../icons/forms/visa.svg");
    }
    &.lock {
      background-image: url("../icons/forms/lock.svg");
    }
  }
}
.select {
  width: 100%;
  background: transparent;
  height: 40px;
  color: var(--textColor);
  padding: 0 1px;
  border: 0;
  border-bottom: 1px solid #31333b;
  outline: none;
  option {
    color: #000;
  }
}
.iconSelect {
  position: relative;
  display: inline-block;
  width: 100%;
  .select {
    padding-left: 20px;
  }
  .icon {
    position: absolute;
    top: 12px;
    left: 0;
    width: 15px;
    height: 15px;
    background: center center no-repeat;

    &.money {
      background-image: url("../icons/forms/money.svg");
    }
  }
}
body[dir="rtl"] {
  .iconInput {
    margin: 10px 0;

    .input {
      padding-left: 0;
      padding-right: 20px;
    }
    .icon {
      left: unset;
      right: 0;
    }
  }

  .iconInputpayment
  {
    .icon
    {
      right: 10px;
    }
  }

  .iconSelect {
    .select {
      padding-left: 0;
      padding-right: 20px;
    }
    .icon {
      left: unset;
      right: 0;
    }
  }
}
