.formPage {
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  margin: 0;
  padding: 0;
}
.formsLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .header {
    margin-top: 60px;
    .logo {
      background: var(--loginLogo) center center no-repeat;
      height: var(--loginLogoHeight);
      width: var(--loginLogoWidth);
      max-width: 350px;
      background-size: var(--background-size);
      //background-size: 160px;
      margin-left: 0;
    }
  }
  .center {
    .form {
      margin: 40px;
      overflow: hidden;
    }
  }
  .footer {
    margin-bottom: 70px;
    p {
      color: var(--textColor);
      font-size: 12px;
    }
  }
}
.smsText{
  
  position: absolute;
  top: 30%;
  color: #70caff;
  font-size: 20px;
  padding: 40px;
}
.smsText p {
  line-height: 30px;
}

.gamezonemodal
    {
      color: black;
      max-width: 100%;
      max-height: 100%;
      padding: 23px 20px;
     
    }
     
    .gamezonemodal h2
    {
      color: #009edd;
      //font-family: cursive;
      line-height: 30px;
    }

    .gamezonemodal p{
      //font-family: cursive;
      font-size: 20px!important;
      color: black!important;
    }

    .gamezonemodal div
    {
      display : flex;
      margin-top: 35px;
    }
    .gamezonemodal button
    {
      min-width: 130px;
      border-radius: 70px;
      background: var(--primaryColor);
      color: var(--textColor);
      padding: 5px 10px;
      min-height: 36px;
      font-size: 16px;
    }
    .gamezonemodal button:first-of-type
    {
      margin-right: 18px;
    }
    .gamezonemodal img
    {
      width: 170px;
    }


    .mtnPhone
    {
      background: url("../../../../assets/images/mtn/phone.svg") no-repeat center center!important;
    }

    .mtnlock
    {
      background: url("../../../../assets/images/mtn/password.svg") no-repeat center center!important;


    }

    .headerMtn
    {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0!important;
      padding: 66px 0;
      background-image:  url("../../../../assets/images/mtn/bg.png")!important;
     // height: 50vh;
     background-color: #ffcb05!important;

    background-color: var(--primary-color);
    background-repeat: no-repeat;
    background-size: cover;
    }

    .formPageMtn
    {
      background: none!important;
    }
    body[dir="rtl"] {
      .gamezonemodal button:first-of-type {
        margin-left: 18px;
        margin-right: 0;

      }
    }



 
