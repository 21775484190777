.operatorSignup {
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  margin: 0;
  padding: 0;
  
}


.welcomePage{
  background-color:#1b1b2a;
  height: 100%;
  width:100%;
  height: 100vh;
  background-image: url("../../../assets/images/3.png");
  background-size: 100% 100%;


}
.welcomePage .logo{
  margin: auto;
  width: 152px;
  padding-top: 100px;
}

.welcomePage .t1,.t2,.t3{
  color: #c1aa6f;
  margin: 10px;
  font-weight: bold;
  
}
.feature{
  color: #c1aa6f;
}
.welcomePage .t1{
  font-size: 20px;
}
.welcomePage .t2{
  font-size: 18px;
}
.welcomePage .t3{
  font-size: 40px;
  border-bottom: 2px solid #c1aa6f;
  width: 200px;
  margin: auto;
  
}
.welcomePage .LetsGo{
  height: 271px;
  width: 212px;
  margin-left: 15px;
  background-image: url('../../../assets/images/LetsGo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0px 58% 40px 33%;
}
#subscribeId{
  visibility: visible;
}
.subscribeId2{
  visibility: visible;
}
@media only screen and (min-width: 600px) {
  .welcomePage .LetsGo{
     
     
      margin:auto;
  }
}

@media(max-width: 376px) {
  .operatorSignupehtio {
    min-height: 180vh!important;
}
}

@media (max-width: 320px)
{
.operatorSignupehtio {
    min-height: 209vh !important;
}
}

