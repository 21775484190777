// .ncellTos {
//   font-size: 13px;
//   margin-top: 20px;
// }

// .links-Section{
//   position: absolute;
//     top: 30%;
//     border: 1px solid red;
// }

// .gamezoneSignup
// {
//   background: rgb(238 90 17);
// }
.formsLayout .formsLayoutjawal2 {
  min-height: 130vh;
}
.jawwalgamezoridncellTos p
{    line-height: 20px;}

.jawwalgamezoridFooter
{
  margin-bottom: 0!important;

}
.condtion-list
{
    text-align: left;
    line-height: 18px;
    padding: 0 5px;
}
.condtion-list ul
{
    padding: 0 ;
}
.condtion-list div
{
    padding: 0;
    color: #fff;
}

.condtion-list div p
{
    color: #fff!important;
}

.condtion-list ul
{
    padding: 0 ;
}

@media(max-width: 540px){
    .formsLayout .jawwalgamezoridFooter {
       margin-bottom: 10px!important; 
  }
  .jawwalgamezoridncellTos p 
  {
    //margin: 8px;
  }
  
  .links-Sectionjawwalgamezorid button{
    margin: 20px auto!important;
  }
    
  
  }

  @media(max-width: 418px){
    //   .formsLayout .jawwalgamezoridFooter {
    //      margin-bottom: 0!important; 
    // }
    // .jawwalgamezoridncellTos p 
    // {
    //   line-height: 16px;
    // }
    
    .links-Sectionjawwalgamezorid button{
      margin: 40px auto!important;
    }
      
    
    }

    @media(max-width: 414px){
      //   .formsLayout .jawwalgamezoridFooter {
      //      margin-bottom: 0!important; 
      // }
      .jawwalgamezoridncellTos p 
      {
        line-height: 16px;
      }
      
      .links-Sectionjawwalgamezorid button{
        margin: 16px auto!important;
      }
        
      
      }

@media(max-width: 411px){
  .formsLayout .jawwalgamezoridFooter {
     margin-bottom: 0 !important; 
}
.jawwalgamezoridncellTos p 
{
  //margin: 10px;
}

.links-Sectionjawwalgamezorid button{
  margin: 40px auto!important;
}
  

}





@media(max-width: 375px){
  .formsLayout .jawwalgamezoridFooter {
    // margin-bottom: 70px!important; 
}
.jawwalgamezoridncellTos p 
{
  line-height: 16px;
}

.links-Sectionjawwalgamezorid button{
  margin: 25px auto!important;
}
  

}



@media(max-width: 360px){
  .formsLayout .jawwalgamezoridFooter {
     margin-bottom: 0!important; 
}
.jawwalgamezoridncellTos p 
{
  margin: 8px;
  font-size: 10px!important;
  line-height: 15px;
}

.links-Sectionjawwalgamezorid button{
  margin: 20px auto!important;
}
  

}

@media(max-width: 320px){
  .formsLayout .jawwalgamezoridFooter {
     margin-bottom: 0!important; 
}
.jawwalgamezoridncellTos p 
{
  margin: 0px;
  font-size: 10px!important;
}

.links-Sectionjawwalgamezorid button{
  margin: 10px auto!important;
}

.jawwalgames-info p 
{
  font-size: 12px;
}
  

}