html[data-theme="nigeria"] {
  --primaryColor: #009edd;
  --activebgTaps : #009edd;
  --borderActiveTab : #009edd;
  --titleColor : #009edd;
  --activeColor:#009edd;
  --borderActiveColor: #009edd;

  --secondaryBgColor: #12151d;
  --lightBgColor: #333742;
  --darkBgColor: #1b1f2a;
  --homeBgColor: #1b1f2a;
  --appBg:#1b1f2a;
  --textColor: #ffffff;
  --subBorderColor:#fff;
  --paymentTextcolor: #ffffff;
    --mainTabsColor: #ffffff;
  --displaytabs: flex;
  --gameDetails: #ffffff;
  --sidebardetailscolor: #ffffff;
  --gamename: #ffffff;
  --unSubscribeColor: #fff;

  --borderRadiusSize: 22px;
  --homeTabs: #27313b;
  --LoveColor: #f4323d;
  --gameBgColor: #1b1f2a;
  --gameBorderradius: 0;
  --gameMargin: 0;
  --background-size: contain;
  --loginLogoWidth: 160px;
  --navbarLogoWidth: 160px;
  --gameDetailsType: rgb(120, 120, 120);
  --Allcolor: #ffffff;
  --colorActive : #fff;
  --profileTextColor : #fff;
  --backgroundcat: #1a1f2a;
  --activeBackground: #1a1f2a;
  --bgHeaderMenucolor: #1a1f2a;
  --linkGamemargin: 20px 3px 5px 15px;
  --imgBorder : 22%;
  --tabMargin: 0;
  --navSearchIconheight : 17px;
  --navSearchIconwidth : 17px;
  --navMenuIconheight : 20px;
  --navMenuIconwidth : 25px;
  --gameBorderB: 1px solid rgb(66, 69, 74);
  --gameMarginTop: 0;
  --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
  --rankingHeader: 211px;
  --rankingHeadercontent: 200;
  --loginLogo: url(../assets/images/logos/default/playit-with-text.svg);
  --navbarLogo: url(../assets/images/logos/default/playit.svg);
  --leaderboard: url(../assets/images/leaderboard/bg.svg);
  --paymentLogo: url(../assets/images/logos/default/fawry_logo.svg);
  --play: url(../assets/images/logos/gamezroid/play.png);
  --exclusiveGame: url(../assets/images/background/EX.png);
  --exclusiveGamePart2: url(../assets/images/background/EX.png);
  --competitionBg: url(../assets/images/competetion-new.jpg);
  --menuBg:url(../assets/icons/burger-menu.svg);
  --searchBg:url(../assets/icons/search.svg);
}