.winners
{
  display: flex;
    flex-flow: column;
    align-items: center;
   // min-height: 73vh;
    width: 35%;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    border: 3px solid white;
    border-radius: 25px;
    padding: 18px 4px;

}

    .winner-details
    {
      color: #00559F;
      margin: auto;
      border-radius: 51px;
      display: flex;
      align-items: center;
      color: #00559F;
      border-radius: 13px;
     // margin-bottom: 20px;
      flex-flow: row;
      background: url("../../assets/images/HomepagecomGamezroid/winnerbg.png") no-repeat center center;
      
      width: 100%;
    height: 71px;
    background-size: cover;
    justify-content: center;
    }

    .userwinner-details
    {
    background: url("../../assets/images/HomepagecomGamezroid/personwinner.png") no-repeat center center;
    background-size: cover;

    }

.winner_point
{
  margin-top: -15px;
  line-height: 18px;

  background-color: white;
    border-radius: 14px;
    padding: 6px 17px;
  
}

.winner_number
{
  background-color: white;
    padding: 5px 3px;
    border-radius: 6px;
    width: 20px;
    height: 15px;
    margin-top: -15px;
    margin-left: 10px;
}
.winner_point p , .winner_number p
{
  margin: 0;
  font-size: 12px;
  font-weight: 900;
  font-family: sans-serif;
}
.winner_info p:nth-child(2)
{
  text-transform: uppercase;
  margin: 7px;
  font-size: 14px;
    color: #00559F;
}
.social p:first-of-type
{
  margin-top: 12px;
    margin-bottom: 0;
}
.social p:last-of-type
{
  //margin: 0;
  margin: 0;
    font-size: 11px;
}
.price p
{
    margin: 5px;
    font-size: 10px;
    color: white;
    margin-top: 8px;
 
}
.price span
{
  font-size: 17px;
    font-weight: 400;
}

.number
{
  font-size: 20px;
    font-weight: 600;
    font-family: sans-serif;
}

.bannerwinners
{
  margin-bottom: 0;
    margin-top: 95px;
}

.bannerwinners h2
{
  text-transform: uppercase;
    letter-spacing: 2px;
    /* font-weight: 500; */
    margin: 8px;

}
.first_winner
{
  margin-top: -81px;
}
.first_winner p:first-of-type{
  text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0;

}
.first_winner p:last-of-type{
  margin-top: 6px;

}


.winners h2
{
 width: 85%;
// width: 332px;
    text-align: left;
    margin: 10px;
    text-transform: uppercase;
    /* font-family: auto; */
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 1px;
    margin-top: 21px;
}

.winner_info
{
  display: flex;
  margin-top: -14px;
  margin-left: 11px;
  width: 49%;

}
.winner_info img 
{
  width: 36px;
  height: 36px;
}

@media(max-width: 1024px){
.bannerwinners
{
  margin-top: 200px;
}
}

@media(max-width: 768px){
  .winners
{
  width: 48%;
}
.bannerwinners
{
  margin-top: 114px;
}
.winners h2 {
  width: 47%;
}
}

@media(max-width: 540px){
  .winners
  {
    width: 88%;
  }
  .winners h2 {
    width: 66%;
  }
  .winner-details
  {
    height: 95px;

  }
}
@media(max-width: 418px){
  .winners h2 {
    width: 85%;
  }
  .winner-details
  {
    height: 71px;

  }
}


@media (max-width: 380px)
{
.winners {
    width: 96%;
}
}

@media (max-width: 360px)
{
.winners {
  padding: 18px 0px;
}
}

@media (max-width: 320px)
{
  .winner-details {
   // width: 290px;
    height: 64px;
    border-radius: 0;
  }
}

body[dir="rtl"]
{
  .winners p 
  {
    font-family: sans-serif;

  }
  .first_winner p ,.bannerwinners h2,.winners h2,.winner_info p ,.winner_info p
  {
    font-family: SourceSansPro-Regular;
  }
  .winners h2
  {
    text-align: right;
  }
}
