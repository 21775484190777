.containerComp
{
    //background: url('../../assets/images/homepagemtn/homepage.jpg');
    background: var(--homepageBgCompetition);
    background-size: cover;
  
}
.compbuttons
{
    display: flex;
    flex-direction: row;
    border: 1px solid red;
}
.compbuttons > div
{
    flex: 50%;
    border: 1px solid green;

}

.leaderboard
{
    // background: url('../../assets/images/competition/leader.png');
    background: var(--leaderboard-icon);

    background-repeat: no-repeat;
    background-size: cover;
    float: left;

}

.tos
{
    // background: url('../../assets/images/competition/info.png');
    background: var(--help-icon);
    background-repeat: no-repeat;
    background-size: cover;
    float: right
    
}
.compbtn button
{
    width: 70px;
    height: 70px;
    cursor: pointer;
    border: none;
    outline: none;
    
}
.moreGame
{
    width: 260px;
    height: 90px;
    cursor: pointer;
   // background: url('../../assets/images/homepagemtn/moregames-en.png');
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    background-color: transparent;
}

.compHomeGames
{
    width: 56%!important;
    margin-top: 2px!important;
    flex-wrap: wrap;

}
.btnspage
{
  min-height: 85px;
  width: 56%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btnhomeramdan
{
  background: url("../../assets/images/HomepagecomGamezroid/homebtbg.png") no-repeat center center;

 background-size: contain;
    color: var(--textColor);
    padding: 10px;
    height: 69px;
    line-height: 9px;
    width: 110px;
    border: 1px solid transparent;
    border-radius: 15px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    font-family: SourceSansPro-Bold;
    transition: all ease 0.4s;
}
.btnhomeramdan p 
{
  margin-top: 11px;
}

.imgGame img
{
  width: 100px;
  height: 100px;
}

.gameCompName {
  font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    color: #fff;
    padding: 2px 7px 9px 7px;
    text-transform: uppercase;



}

.coins
{
  width: 100px;
  height: 75px;
  position: absolute;
  top: 22%;
  left: 24%;

}

body[dir='rtl']
{
  .btnhomeramdan
{
  font-size: 20px;
}
}



@media(max-width: 1024px){
  .compHomeGames {
    width: 61% !important;
}
.coins
    {
      left: -26px;
    }
}

  @media(max-width: 950px){
    

    .compHomeGames {
        width: 30% !important;
    }
    
    .containerComp
    {
        min-height: 113vh!important;
        background-size: cover;
    }
    
    
  }


@media(max-width: 768px){
  .btnspage
  {
    width: 62%;
  }
    .compbtn {
        width: 65%!important;
    }
    .spaceTop
    {
        height: 3vh!important;
    }
    .compHomeGames {
        width: 65% !important;
    }
    .containerComp
    {
        min-height: 113vh!important;
        background-size: cover;
        
    }

    .imgGame img {
      width: 90px;
      height: 90px;
  }

  .section-home
  {
    width: 72%!important;
  }
  
    
  }
  
  @media(max-width: 540px){
    .btnspage
  {
    width: 75%;
  }
   
    
    .containerComp
    {
        height: 719px!important;
        background-size: cover;

        
    }
    .compHomeGames {
        width: 77% !important;
    }
    .spaceTop {
        height: 7vh !important;
    }
    .section-home
    {
      width: 86%!important;
    }

    .compbtn {
      width: 80% !important;
  }
    
  }

  @media(max-width: 418px){
    .btnspage
    {
      width: 100%;
    }
   

    .containerComp {
        min-height: 112vh;
        background-size: cover;

    }
    .compbtn
    {
        width: 100%!important;

    }
    .spaceTop {
        height: 6vh !important;
    }
    
  }
  @media(max-width: 418px){
   

    .containerComp
    {
        height: 873px !important;
        background-size: cover;

        
    }
    .compHomeGames {
        width: 100% !important;
    }
    
  }
  
  @media(max-width: 411px){
    .spaceTop {
        height: 2vh !important;
    }
    .containerComp
    {
        background-size: cover;

        
    }
    
  }
  @media(max-width: 400px){
    .containerComp
    {
        height: 838px!important;
        background-size: cover;

        
    }
  
    
  }
  
  
  
  @media(max-width: 376px){
    .containerComp
    {
        height: 814px !important;
        min-height: 108vh!important;
        background-size: cover;

        
    }

    .coins
    {
      top: 102px;
    }
     
  }
  
  @media(max-width: 360px){
    .spaceTop {
        height: 5vh !important;
    }
    .coins
    {
      top: 120px;
    }
      
  }
  
  @media(max-width: 320px){
    .links-SectionCompetition {
      width: 98%!important;
    }
    .containerComp
    {
        height: 567px!important;
        min-height: 130vh!important;
        background-size: cover;

        
    }
    .compHomeGames {
        width: 100% !important;
    }

    .imgGame img {
      width: 75px;
      height: 75px;
  }

  .coins
  {
    top: 100px;
    left: -3px;
  }

  .btnhomeramdan
  {
    width: 100px;
  }
    
  
  

    // .links-SectionCompetition {
    //     top: 8%!important;
    // }

    .headerCompetion {
        margin-top: 0!important;
    }
    
    
  }
  
  @media(max-width: 280px){
    
    .containerComp
    {
        height: 651px !important;
        min-height: 114vh!important;
        background-size: cover;

        
    }
    .compHomeGames {
        width: 100% !important;
    }
    .links-SectionCompetition {
      width: 100% !important;
  }
  .logoComp
  {
    width: 130px!important;
  }
    
  }
//   @media screen and (orientation:landscape) {
//     .containerComp {
//         min-height: 170vh !important
//     }
//   }
  @media (max-device-width: 1024px) and (orientation:landscape) {
    .containerComp {
        min-height: 109vh !important;
    }
  }
  @media (max-device-width: 993px) and (orientation:landscape) {
    .containerComp {
        min-height: 170vh !important;
    }
  }
  @media (max-device-width: 882px) and (orientation:landscape) {
    .containerComp {
        min-height: 162vh !important;
    }
  }
  @media (max-device-width: 823px) and (orientation:landscape) {
    .containerComp {
        min-height: 172vh !important;
    }
  }

  @media (max-device-width: 768px) and (orientation:landscape) {
    .containerComp {
        min-height: 184vh !important;
    }
  }

  @media (max-device-width: 640px) and (orientation:landscape) {
    .spaceTop {
        height: 4vh !important;
    }
    .containerComp {
        min-height: 162vh !important;
    }
  }
  @media (max-device-width: 653px) and (orientation:landscape) {
    .spaceTop {
        height: 0 !important;
    }
    /*.containerComp {
        min-height: 226vh !important;
    }*/
   
  }
.ice
{
    width: 100%;
   // height: 50px;
   // border: 1px solid red;
    position: absolute;
    top: 0;
    z-index: 200;
    overflow: hidden;
   //display: none;
   pointer-events: none
}

// * {
//     overflow: hidden;
//   }
  
  // Definition of snow length
  // NOTE: length should be same value with HTML elements
  $SNOWDROPS_LENGTH: 100;
  
  // Wrapper for snows, this shows background
  .g-snows {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    //background-color: navy;
    //background-image: linear-gradient(to bottom, navy, darkgray);
  }
  
  .g-snows > li {
    // NOTE: Opacity zero is required due to appearance of snow.
    opacity: 0;
    position: absolute;
    top: 0;
    border-radius: 100%;
    //background-color: #FFFFFF;
    background: url('../../assets/images/competition/partical1.png');
    // background: url('../../assets/images/competition/partical2.png');

    
    background-repeat: no-repeat;
    background-size: 100% auto;
    animation-name: snow-drop;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    
    // Bases of random snows effect
    $snow-size-base: 30;
    $browser-buffer: 50;
    $left-position: 100 + $browser-buffer;
    $animate-speed-base: 10000;
    $minimum-falling-speed: 5000;
    $animate-delay-base: 5000;
    $blur-base: 5;
  
    @for $i from 1 through $SNOWDROPS_LENGTH {
      $size: abs(random($snow-size-base) - random($snow-size-base)) + 0px;
  
      &:nth-child(#{$i}) {
        left: abs(random($left-position) - random($left-position)) + 0%;
        width: $size;
        height: $size;
        animation-duration: abs(random($animate-speed-base) - random($animate-speed-base)) + $minimum-falling-speed + 0ms;
        animation-delay: abs(random($animate-delay-base)) + 0ms;
        filter: blur(abs(random($blur-base) - random($blur-base)) + 0px);
      }
    }
  } 
    
  @keyframes snow-drop {
    $window-height-threshold: 1.5;
  
    0% {
      transform: translate(0, 0);
      opacity: 0.5;
      margin-left: 0;
    }
  
    10% {
      margin-left: 15px;
    }
  
    20% {
      margin-left: 20px;
    }
  
    25% {
      transform: translate(0, 250px / $window-height-threshold);
      opacity: 0.75;
    }
  
    30% {
      margin-left: 15px;
    }
  
    40% {
      margin-left: 0;
    }
  
    50% {
      transform: translate(0, 500px / $window-height-threshold);
      opacity: 1;
      margin-left: -15px;
    }
  
    60% {
      margin-left: -20px;
    }
  
    70% {
      margin-left: -15px;
    }
  
    75% {
      transform: translate(0, 750px / $window-height-threshold);
      opacity: 0.5;
    }
  
    80% {
      margin-left: 0;
    }
  
    100% {
      transform: translate(0, 1000px / $window-height-threshold);
      opacity: 0;
    }
  }




