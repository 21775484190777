.titel{
    text-align: center;
    color: #009edd;
}
.Page-bgImg{
    margin: -20px;
    height: 400px;
    width: auto;
    background: url(/static/media/modelAds.9c325c7c.jpg) center center no-repeat;
}
