@import '~csshake/scss/csshake-hard.scss';
@import'../../../node_modules/csshake/dist/csshake.min.css';
@import url('https://fonts.googleapis.com/css?family=Anton');

    
    /*.wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;

        .item {
            padding: 50px;
            display: flex;
            align-items: center;
            flex-direction: column;

            .icon {
                font-size: 60px;
                cursor: pointer;
            }

            p {
                font-size: 25px;
                color: #fff;
                font-family: Anton;
                font-weight: 700;
                letter-spacing: 1px;
            }
        }
    }*/


/*.games>div>a>div
{
    color:var(--gamename) ;
}

.games>div
{
    background-color: var(--gameBgColor);
    border-radius: var(--gameBorderradius);
    margin-right: var(--gameMargin);
}
.games>div>a
{
    margin : var(--linkGamemargin) !important;
    
}
.games>div>a img
{
    border-radius: var(--imgBorder)!important;
}*/


.carousel .thumbs-wrapper
{
    display: none;
}

img:not([alt])
{
    outline: none!important;
}
.carousel .carousel-status {
    //margin-top: 10px;
    display: none
}

body[dir="rtl"] {
    .carousel .slider-wrapper
    {
        direction: initial;
    }

.carousel.carousel-slider
{
    //direction: rtl;
}
.carousel .control-prev.control-arrow
{
    /*right: 0;
    left: auto;*/
}
.carousel .control-next.control-arrow
{
    /*left: 0;
    right: auto;*/
}
}


.banner img
{
    width: 100%;
    height: 100%;
    //background-image: url('../../assets/images/competetion-new.jpg');
    
    /*background-repeat: no-repeat;
    background-size: contain;

    height: 426px;
    background-size: cover;*/
}
.gamezroidbanner
{
    width: 50%;
    margin: auto;
}
.Enter
{
    //background-image: url('../../assets/images/enter.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px;
    width: 18%;
    padding: 14px 3px;
    height: 75px;
    background-color: #25807600;
    position: fixed;
    top: 300px;
    left: 43%;
    font-weight: 800;

}

@media(max-width: 1050px){
    .Enter
    {
        top: 267px;
        left: 42%;



    }

  
    
    
  }

  @media(max-width: 950px){
    .Enter
    {
        top: 207px;
        left: 42%;



    }

  
    
    
  }


@media(max-width: 768px){
    .Enter
    {
        top: 198px;
        left: 42%;



    }

    .gamezroidbanner
{
    width: 100%;
}

  
    
    
  }
  
  @media(max-width: 540px){
    .Enter
    {
        top: 147px;
        left: 42%;



    }
    
    
    
  }
  
  @media(max-width: 418px){
    .Enter
    {
        top: 106px;
        left: 42%;



    }

  
    
  }
  @media(max-width: 400px){
  
    
  }
  
  
  
  @media(max-width: 375px){
    .Enter
    {
        top: 98px;
        left: 42%;



    }
    
  
   
    
  }
  
  @media(max-width: 360px){
    .Enter
    {
        top: 92px;
        left: 42%;



    }
      
    
  
    
  }
  
  @media(max-width: 320px){
    .Enter
    {
        top: 81px;
        left: 42%;



    }
    
    
  }
  
  @media(max-width: 280px){
    
    
    
  }

