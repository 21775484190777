html[data-theme="gamezroid"] {
    --primaryColor:#00bcd4;
    --borderActiveTab : #00bcd4;
    --activebgTaps : #00bcd4;

    --titleColor : #00bcd4;
    --secondaryBgColor: #3f484e;
    --BgNavScroll : #3f484e;
    --lightBgColor: #383b40;
    --darkBgColor: #1b1f2a;
    --homeBgColor:#3f484e;
    --appBg:#3f484e;
    --bgHeaderMenucolor:#00bcd4;
    --activeColor:#12151d;
    --borderActiveColor: #00bcd4;
    --homeTabs: #ffffff;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : #ffffff;
    --mainTabsColor: #3f484e;
    --sidebardetailscolor: #ffffff;
    --LoveColor:#f4323d;
    --gamename:#1b1f2a;
    --unSubscribeColor: #1b1f2a;

    --profileTextColor : #fff;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;
    --borderRadiusSize : 5px;
    --background-size: 'contain';
    --Allcolor: #00bcd4;
    --backgroundcat :#00bcd4;
    --colorActive : #fff;

    --activeBackground: #00bcd4;
    --gameDetailsType : rgb(120, 120, 120);
    --loginLogoWidth : 180px;
    --navbarLogoWidth : 240px;
    --navbarcomLogoWidth : 160px;
    --gameBgColor : #ffffff;
    --gameBorderradius : 6px;
    --gameMargin : 10px;
    --imgBorder : 50%;
    --tabMargin :25px;
    --linkGamemargin : 20px 15px 15px 20px;
    //--linkGamemargin : 20px 0px 20px 20px;
    --gameBorderB :1px solid #ffffff;
    --gameMarginTop : 25px;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --loginLogo: url(../assets/images/logos/gamezroid/loginLogo.png);
    --navbarLogo: url(../assets/images/logos/gamezroid/navLogo.png);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --subscribe: url(../assets/images/background/subscribe.jpg);
    --play : url(../assets/images/logos/gamezroid/play.png);
    --homepageBgCompetition : url(../assets/images/bg-banners/home-competition.jpg);
    --leaderboard-icon: url(../assets/images/HomepagecomGamezroid/leader.png);
    --help-icon: url(../assets/images/HomepagecomGamezroid/help.png);
    --competitionLogo: url(../assets/images/HomepagecomGamezroid/logo1.png);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);





     }