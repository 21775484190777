body[dir="rtl"] {
    .gameBody .desc ,.gameBody .descLess,.gameBody .seeP{
    text-align: right;
    }
   
    
    .gameHeader .titleDetails{
        padding: 20px 25px 10px 5px;
    }
  }
