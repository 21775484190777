.ncellTos {
  font-size: 13px;
  margin-top: 20px;
}

/*.links-Section{
  position: absolute;
    //top: 9%!important;
    top: 30%!important;

}*/

.links-Sectionksa
{
  top:10%!important
  
}
.headerFree
{
  margin-top: 18px!important;
}

.play
{
 //background: var(--play) center center no-repeat;
 background: url(../../../assets/images/logos/gamezroid/play.png) center center no-repeat;
 height: 175px;
 background-size: var(--background-size)!important;
}

.comBg
{
  background: url(../../../assets/images/logos/free/img1.png) center center no-repeat;
  height: 334px;


}
.centersign 
{
  width: 75%;
  margin-top: 332px;
}
.btnFree
{
  background-color: #38a02c!important;
  min-width: 177px!important;
  border-radius: 44px!important
}
.playFree
{
  position: relative;
    top: 56%;
}
.honers
{
  position: relative;
    top: 57%;
    width: 57%;
    margin: 0 auto;

}
.imgs img{
  width: 150px;
  height: 100px;
  border-radius: 13px;
}

.ncellTosFree h3 
{
  margin: 0;
}
@media(max-width: 650px){
  .imgs img{
    width: 90%;
    height: 100px;
  }
}
.honers p
{
  font-weight: 200;
  font-size: 11px;
}
.honers
span{
  font-weight: 900;
  font-size: 40px;

}

.resend
{
    margin-top: 25px;
}
.imgs
{
  width: 100%;
    height: 100px;
    display: flex;
    margin: 0 auto;
    margin-bottom: 13px;

}

.imgs div{
  width: 48%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.imgs p 
{
  margin: 0;
    font-weight: 100;
    font-size: 12px;
}

.imgs div:first-of-type{
  //margin-right: 10%;
}

.input_phone
{
  margin : 0!important
}

body[dir="rtl"] {
  
  .input_phone
  {
   
    padding-right: 26px!important;

  }

  .iconFree
  {
    right: 8px!important;
  }

}

.verifymodal,.subscribemodal
{
  //border: 1px solid red;
  height: 100%;
  color: black;
}



.congrut{
  background: url('../../../assets/images/background/subscribe.jpg') center center no-repeat;
  height: 100%;
  background-size: cover;

}

.row
{
  display: flex;
  flex-wrap: wrap;
  text-align: center!important;
  width: 100%;
}

.row .px-5
{
    flex: 0 0 33%;
    
    max-width: 33%;
    
}

.gamezoridcontent
{
  text-align: center;
}
.games-info
{
  max-height: 100%;

}
.games-info span,p
{
  font-weight: bolder;
    font-size: 16px;
}
.games-info p
{
  margin-top: 0.7em;
  font-size: 16px;

}

.games-info img
{
   vertical-align: middle;
    border-style: none;
    margin-left: 5px;


}
.timer p{
  line-height: 5.8px;
  color: #f2d12c;
}
.gamezroidverify
{
  background: var(--gamezroidcolor)!important;
    margin: 20px auto!important;
    color: black!important;
    border: 1px solid #bb2f0c!important;
    border-radius: 32px!important;
    
}


body[dir="rtl"] {
  .games-info img
{
    margin-right: 5px;
    margin-left: 0;
}
.steps ul:nth-child(2)
{
  text-align: right;
}

.stepLists ul{
  padding-right: 10px;
}


}

.freesubscribe
{
    font-weight: 300!important;
    font-size: 11px!important;
    span {
      font-weight: bold!important;
      font-size: 11px!important;
    }
}

.fixedprice
{
  font-weight: 600;
  font-size: 14px;
}
.footer .freeenriched
{
  font-size: 15px!important;

}

.steps
{
  //text-align: center;
  //display: flex;
}
.steps h4
{
  //font: normal normal normal 20px/27px American Typewriter;
}
.steps ul{
  list-style-type: none;
}
.steps ul:nth-child(1)
{
  line-height: 47px;
  padding-top: 18px;
}

.steps ul:nth-child(2)
{
  text-align: left;
}

.steps img{
  padding-right: 12px;
}
.steps img:nth-child(2){
  padding-right: 24px;
}

.stepLists
{
   display: inline-flex;
   line-height: 25px;

}
.stepLists ul{
  padding-left: 0;
}
.decilimer
{
  font-weight: 100!important;
  font-size: 10px!important;
}

.operatorSignup
{
  min-height : 100vh !important;
}

.btntest
{
  color: 'white'!important;
  background-color: '#BB2F14'!important;
  border: '1px solid red'!important;
}
.phonenumber 
{
  color: black!important;
}




//responsive mobile

@media(max-width: 1024px){
  
  .centersign {

   // margin-top: 0;
  }
  
}

@media(max-width: 768px){
  
  .centersign {

    //margin-top: 108px;
  }
  
}

@media(max-width: 540px){
  
  .centersign {

    margin-top: 332px;
  }
  
}

@media(max-width: 411px){
  .centersign {
    margin-top: 298px;
  }
  
}
@media(max-width: 400px){
  .centersign {
    margin-top: 332px;
  }
  
}



@media(max-width: 375px){
  

  .centersign {
    margin-top: 332px;
  }
  
}

@media(max-width: 360px){
  
  .centersign {

    margin-top: 332px;
  }
  
}

@media(max-width: 320px){
  .links-SectionFree
  {
    width: 88%!important;
  }

  .centersign {
    width: 88%;

    margin-top: 332px;
  }
  
}

@media(max-width: 280px){
  
  .links-SectionFree
  {
    width: 100%!important;
  }

  .centersign {
    width: 100%;

  }
  
}



