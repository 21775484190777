.packages
{
  display: flex;
    flex-flow: row;
    align-items: center;
   //flex-wrap: wrap;
    justify-content: space-around;
    width: 64%;
   margin-left: auto;
    margin-right: auto;
    align-content: space-between;
    height: 110px;
    margin-bottom: 40px;
    border: 3px solid white;
    padding: 23px 15px;
    border-radius: 24px;
    background: url("../../assets/images/HomepagecomGamezroid/pointbg.png") no-repeat center center;
   
}

.golden
{
  background: url("../../assets/images/HomepagecomGamezroid/bg-package.png") no-repeat center center;
  height: 515px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background-size: contain;
    width: 35%;
    margin: auto;


}
.verifypopphone
{
  display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: center;
    top: 0;
    align-items: center;
}
.phone_verify
{
  background: url("../../assets/images/HomepagecomGamezroid/verifybg.png") no-repeat center center;
  border: 4px solid white;
    border-radius: 24px;
    width: 26%;
    height: 179px;
    // position: relative;
    // top: -242px;


}
.phone_verify input
{
  background-color: white;
  border-radius: 10px;
  width: 85%;
  color: black!important;
}
.inputpack::placeholder
{
  margin: 0 5px!important;
  color: green!important;
}
.phone_verify button
{
  background-color: #00E226;
  min-width: 48%;
  margin-top: 15px;
  outline: none;
  min-height: 35px;
}
.first_package
{
  margin-top: 20vh;
}

    .package-details
    {
      display: flex;
      align-items: center;
      color: #00559F;
      border-radius: 33px;
      margin-bottom: 20px;
      flex-flow: column;
      // background: url("../../assets/images/HomepagecomGamezroid/price.svg") no-repeat center center;
    width: 104px;
      height: 117px;
    }

    .loadMoney
    {
      // background: url("../../assets/images/HomepagecomGamezroid/COINS2.gif") no-repeat center center;
      background: url("../../assets/images/HomepagecomGamezroid/coin.png") no-repeat center center;

      // width: 113px;
      // height: 103px;
      width: 100px;
      height: 100px;
      margin-top: 20px;
    //   background-color: white;
    // opacity: 0.6;
    animation-name: rotation;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 1s;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
    }


    @keyframes rotation {
      0% {
        transform: rotate3d(0, 1, 0, 0deg);
      }
      50% {
        transform: rotate3d(0, 1, 0, 180deg);
      }
      100% {
        transform: rotate3d(0, 1, 0, 360deg);
      }
    }

     
      .popdiscount
    {
      display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .popdiscount:after
    {
      content:'';
      background: black;
      min-height: 100vh;
    width: 100%;
      height: 100%;
      position:absolute;
      top:0;
      left:0;
      opacity:0.5;
z-index: 360;
    }

    .poptext
    {
      background: url("../../assets/images/HomepagecomGamezroid/poptext.png") no-repeat center center;
      height: 245px;
      width: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 400;
      outline: none;

    }

    .poptextpackages
    {
      flex-flow: column;
      padding-top: 98px;
    }

    .poptext p
    {
      margin-top: 112px;
      font-size: 16px !important;
    text-transform: capitalize;
    font-family: cursive!important;
    outline: none;
    }

    .poptext span
    {
      font-size: 33px;
    color: #0a3484;

    }

    .poptextpackages p 
    {
      margin-top: -9px;
      width: 80%;

    }
   

.package-details p
{
  //margin: 0;
  
}
.package-details p:first-of-type
{
  margin-bottom: 2px;

  //margin: 0;
}
.package-details p:nth-child(2)
{
  margin: 3px;
  font-size: 12px;
    color: #009EDD;
  //margin: 0;
}
.social p:first-of-type
{
  margin-top: 12px;
    margin-bottom: 0;
}
.social p:last-of-type
{
  //margin: 0;
  margin: 0;
    font-size: 11px;
}
.price p
{
    margin: 5px;
    font-size: 10px;
    color: white;
    margin-top: 8px;
 
}
.price span
{
  font-size: 17px;
    font-weight: 400;
}

.number
{
  font-size: 20px;
    font-weight: 600;
    font-family: sans-serif;
}

.bannerpack
{
  margin-bottom: 6px;
    margin-top: 130px;
}

.bannerpacker img
{

}

.social
{
  display: flex;
 // flex-flow: row;
 flex-wrap: wrap;
  align-items: center;
  width: 68%;
  margin: auto;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  border: 3px solid white;
  padding: 20px 10px;
  border-radius: 24px;
  background: url('../../assets/images/HomepagecomGamezroid/socialbg2.png') no-repeat center center;
  //background-size: cover;
 // background-color:' rgb(224 224 228 / 7%)';
}

.share
{
  //background: url("../../assets/images/HomepagecomGamezroid/share.svg") no-repeat center center;
  width: 126px;
  height: 91px;
  background-repeat: no-repeat;
  background-position: center;
}

.social a{
  color: white;
}
.like
{
  //background: url("../../assets/images/HomepagecomGamezroid/like.svg") no-repeat center center;
  width: 126px;
  height: 91px;
  background-repeat: no-repeat;
  background-position: center;

}
.noactiveshare ,.noactivelike
{
  background: url("../../assets/images/HomepagecomGamezroid/xxxx.svg") no-repeat center center;
  width: 126px;
    height: 90px;
    position: absolute;


}
.notes
{
  //background: url("../../assets/images/HomepagecomGamezroid/bou.png") no-repeat center center;
  height: 106px;
  width: 273px;
  margin: auto;
 // margin-top: 60px;
}

.notes p
{
  width: 80%;
    margin: auto;
    font-size: 18px;
}
body[dir="rtl"]
{
  .notes p
  {
    font-family: sans-serif!important;
  }
  .price
  {
    direction: ltr;
  }

  .poptext p
  {
    font-size: 25px !important;
  }

  .poptextpackages p 
  {
    
      font-size: 16px !important;
    }

  

}

@media(max-width: 1024px){

.phone_verify
{
  margin-top: -75%;
}
}

@media(max-width: 768px){
  
  .phone_verify
  {
    width: 41%;
    margin-top: 0%;

  }
 
  .packages_content
  {
    width: 47%!important;

  }
  .packages
{
  width: 85%;
}
.social
{
  width: 92%!important;
}
.golden
{
  width: 100%;
}
}
@media(max-width: 600px){
  .packages_content
  {
    width: 67%!important;

  }
  .phone_verify
  {
    width: 59%;
  }
}

@media(max-width: 500px){
  .packages_content
  {
    width: 85%!important;

  }
  .bannerpack {
    margin-top: 85px;
}
.phone_verify
{
  width: 76%;
}
}

@media(max-width: 376px){
  .packages_content
  {
    width: 88%!important;

  }
}



@media(max-width: 370px){
  .packages_content
  {
    width: 90%!important;

  }
}

@media(max-width: 321px){
  .packages_content
  {
    width: 100%!important;

  }
  .packages {
    width: 83%;
}
.social
{
  padding: 20px 5px;
}
 
 

}
