.load
{
    width: 100%;
    position: absolute;
    z-index: 145;
    top: 0;
    background: black;
    opacity: 0.5;
}
.operatorSignupasiacell
{
    background-color: #fff;
}
.carousel.carousel-slider
{
    border-radius: 73px;
}

.carousel .slide img
{
    height: 350px!important;
}

@media(max-width: 768px){
    .carousel.carousel-slider
{
    border-radius: 23px;
}
.carousel .slide img
{
    height: 175px!important;
}
  
  }

// .formsLayoutasiacell
// {
//   min-height: 58vh!important;

// }