html[data-theme="gamelibia"] {

--primaryColor: #01aace;
--activebgTaps : #01aace;
--borderActiveTab : #01aace;
--titleColor : #01aace;
--activeColor:#fff;
--borderActiveColor: #fff;
--vodColor: #ff3030;
--orgColor: #ff6e23;
--etsColor: #008734;
--mobColor: #01aace;
--stcColor: #613bff;
--sabafonColor: #ff6e23;
--zainColor: #3ef886;
--ooredoocolor :#ff6e23;
--zainjordancolor:#37ace5;
--gamezroidcolor: #f2d12c;
--darkPrimaryColor: #004f6f;
--secondaryBgColor: linear-gradient(135deg, rgba(171,46,181,1) 14%, rgba(1,170,206,1) 100%);
--BgNavScroll : #12151d;
--loginLogoHeight : 51px;
--lightBgColor: #333742;
--darkBgColor: #1b1f2a;
--homeBgColor:#ffffff;
--appBg:#1b1f2a;
--textColor: #fff;
--subBorderColor:#ab2eb5;
--paymentTextcolor : #ffffff;
  --mainTabsColor: #ffffff;
  --displaytabs: none;
--gameDetails : #000000;
--sidebardetailscolor: #000000;
--gamename:#000000;
--unSubscribeColor: #fff;

--sSubtabColor: #fff;

--borderRadiusSize : 22px;
--homeTabs: #27313b;
--LoveColor: #f4323d;
--gameBgColor : #ffffff;
--gameBorderradius : 0;
--gameMargin : 0;
// --background-size: 160px;
--loginLogoWidth : 160px;
--navbarLogoWidth : 160px;
--navSearchIconheight : 17px;
  --navSearchIconwidth : 17px;
  --navMenuIconheight : 20px;
  --navMenuIconwidth : 25px;
--gameDetailsType : rgb(120, 120, 120);
--Allcolor: #0645b1;
--backgroundcat :#ab2eb5;
--activeBackground: #ab2eb5;
--bgHeaderMenucolor:linear-gradient(135deg, rgba(171,46,181,1) 14%, rgba(1,170,206,1) 100%);
--linkGamemargin : 20px 3px 5px 15px;
--imgBorder : 22%;
--tabMargin :0;
--gameBorderB :1px solid rgb(66, 69, 74);
--gameMarginTop : 0;
--heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
--rankingHeader : 211px;
--rankingHeadercontent : 200;
--colorActive : #fff;
--paddingTaps : 0;
--marginScroll: 0 0;
--opacityScroll : 'none';
--profileTextColor : #000;
--loginLogo: url(../assets/images/logos/default/playit.svg);
--navbarLogo: url(../assets/images/logos/default/playit.svg);
--leaderboard : url(../assets/images/leaderboard/bg.svg);
--paymentLogo : url(../assets/images/logos/default/fawry_logo.svg);
--play : url(../assets/images/logos/gamezroid/play.png);
--games_icon : url(../assets/images/logos/gamezroid/games.png);
--exclusiveGame : url(../assets/images/background/EX.png);
--exclusiveGamePart2 : url(../assets/images/background/EX.png);
--competitionBg : url(../assets/images/competetion-new.jpg);
--menuBg:url(../assets/icons/burger-menu.svg);
--searchBg:url(../assets/icons/search.svg);
--insideBg:'';
--loginBg:'';
--background-size: contain;
}