.App {
  text-align: center;
  position: relative;
  max-width:1200px;
  margin: auto;
  // background-image: var(--appBg);
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center center;
  // background-attachment: fixed;
  background-image: var(--appBg);
 background-repeat: no-repeat;
 background-size: 100% 100%;
 background-attachment: fixed;
 // padding-top: var(--paddingApp);
  background-position: center;
}
.full-screenable-node
{
  overflow: auto;
}

.sc-hzDKRC
{
  background-image: url('./assets/images/bg-banners/bg.png') !important;
}




.globalcoins
{
  width: 100px;
    height: 75px;
    position: absolute;
    top: 2%;
    left: 6%;
    z-index: 4;
}
.globalcoins img , .coins img
{
  
    -webkit-animation-name:              shake;    
    -webkit-animation-duration:          2s;
    -webkit-animation-iteration-count:   infinite;
    -webkit-animation-timing-function:   linear;
    -webkit-transform-origin:            50% 100%;
}


@-webkit-keyframes shake {
  0%  { -webkit-transform:     translate(2px, 1px) rotate(0deg); }
  10% { -webkit-transform:     translate(-1px, -2px) rotate(-2deg); }
  20% { -webkit-transform:     translate(-3px, 0px) rotate(3deg); }
  30% { -webkit-transform:     translate(0px, 2px) rotate(0deg); }
  40% { -webkit-transform:     translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform:     translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform:     translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform:     translate(2px, 1px) rotate(-2deg); }
  80% { -webkit-transform:     translate(-1px, -1px) rotate(4deg); }
  90% { -webkit-transform:     translate(2px, 2px) rotate(0deg); }
  100%{ -webkit-transform:     translate(1px, -2px) rotate(-1deg); }
 }

@media(max-width: 770px) {
  .App {
    background-size: cover;
  }
  .renewaltext
  {
    width: 41%!important;
   // top: 15%!important;
  }

  .globalcoins
{
  left: 0%;
}

}
@media(max-width: 540px){
  .renewaltext
  {
    width: 55%!important;
  }
}
@media(max-width: 500px){
  .renewaltext
  {
    width: 77%!important;
  }
}

.renewal
{
  display: flex;
    width: 100%;
    min-height: 100vh;
    position: absolute;
    justify-content: center;
    top: 0;
    align-items: center;
    z-index: 400;
}

.renewaltext
{
  background: url("../src/assets/images/HomepagecomGamezroid/sharelinks.png") no-repeat center center;
  border: 4px solid white;
    border-radius: 24px;
    width: 350px;
    height: 155px;
    position: fixed;
    top: calc(100% - 50%);
    padding-top: 35px;
  

}
.renewaltext button
{
  background-color: #00E226;
  min-width: 48%;
  margin-top: 15px;
  outline: none;
  min-height: 35px;
}
